
export default {
    // 頂部導航欄
    navbar: {
        Dashboard: '首页',
        VirtualCard: '虚拟卡',
        CardDetails: '卡明细',
        AccountDetails: '账户明细',
        AccountSettings: '账户设定',
        Funds: '资金'
    },
    // 側邊欄
    ceNavbar: {
        CardList: '虚拟卡', // 卡列表
        Create: '创建', // 創建
        Funds: '资金', // 資金
        Accounts: '账户' // 賬戶 
    },
    // 頭像下拉框
    avatarSelect: {
        Account: '账户', // 賬戶
        Notice: '通告',  // 通知
        Message: '讯息', // 訊息
        Set: '设定', // 設定
        DataAuthentication: '资料认证', // 資料認證
        PaymentSettings: '支付设定', // 支付設定
        Logout: '登出' // 登出
    },
    // 資金頁
    Funds: {
        WireTransfer: '银行电汇', // 銀行電匯
        AddFunds: '汇入资金', // 匯入資金
        AliPay: '支付宝', // 支付寶
        TransforFunds: '转入资金', // 轉入資金
        // 表格字段
        Table: {
            Time: '时间', // 时间
            Amount: '金额', // 金额
            FundingSource: '转入方式', // 转入方式
            Status: '状态', // 状态
        },
        // 匯入資金頁
        Inwardrem: {
            AddUsdToYourAccount: "汇入资金至您的账户", // 匯入資金至您的賬戶
            Dec: "请通过您的资金账户汇入美元至以下收款账户，汇款到帐后将增值您的账户余额", // 請通過您的資金賬戶匯入美元至以下收款賬戶，匯款到賬后將增值您的賬戶餘額
            AccountName:"账户名", // 賬戶名
            AccountNumber: "账户号码", // 賬戶號碼
            BankName: "银行名称", // 銀行名稱
            AccountInformation: "收款账户信息", // 收款賬戶信息
            BankCode: "银行代码", // 銀行代碼
            BranchCode: "分行代码", // 分行代碼
            TipsInfo1: "此收款账户通过电汇（SWIFT）和当地银行转账、CHATS（RTGS）、ACH、FPS接受资金", // 此收款账户通过电汇(SWIFT)和当地银行转账、CHATS(RTGS)、ACH、FPS接受资金
            TipsInfo2: "通过本地转账不收取任何费用，应在一个工作日内到帐。电汇则需要多1-2工作日时间", // 通过本地转账不收取任何费用，应在一个工作日内到账。 电汇则需要多1-2工作日时间
            YourFundAccount: '您的资金账户',
            // 彈窗
            Tips: "提示",
            TipsContent: "请先添加银行账户信息", // 请先添加银行账户信息
            TipsButton1: "取消", // 取消
            TipsButton2: "确认", // 確認
         },
        //  賬戶頁
        Accounts: {
            TipInfo: '您可使用验证通过的资金账户汇款美元至指定收款账户，快速增值您的账户余额',
            AccountName:"账户名", // 賬戶名
            AccountNumber: "账户号码", // 賬戶號碼
            BankName: "银行名称", // 銀行名稱
            BankCode: "银行代码", // 銀行代碼
            BranchCode: "分行代码", // 分行代碼
            Status: '状态',
            // 
            AddFundAccount: '新增资金账户', // 新增资金账户
            Optional: '(选填)',
            BankAccountCertificate: '银行账户证明', // 銀行賬戶證明
            SelectFile: '选取文件', // Select file
            FileTip: '银行账户证明文件须与您填写的账户名一致,可以为银行开户证明、包含账户及户名的汇款单',
        }
    },
    // 首頁
    Dashboard: {
        SecureYourAccount: '安全保障您的数据', // 安全保障您的數據
        CurrentBalance: '当前余额', // 當前餘額
        TotalEntry: '总入账', // 縂入賬
        HowToCalculateTheAboveData: '以上数据如何计算?', // 以上數據如何計算
        // Tip1: '当前余额：充值总额 - 支出总额（包括体现、手续费、卡充值等） `总入账：充值总额 - 体现总额`',
        Tip1: {
            Row1: '当前余额：',
            Row2: '充值总额 - 支出总额（包括体现、手续费、',
            Row3: '卡充值等） ',
            Row4: '总入账: ',
            Row5: '总入账：充值总额 - 体现总额.'
        },
        TimelyMonitorCardUsage: '及时监控用卡情况', // Timely monitor card usage
        ConsumptionInRecent24Hours: '近24小时消费', // 近24小時消費
        NewInRecent24Hours: '近24小时新建', // 近24小時新建
        // Tip2: '近24小时消费：账户内所有卡近24小时支出 + 收入（负值表示支出额 > 收入额，正值表示收入额 > 支出额）`近24小时新建：账户近24小时新建卡数量`',
        Tip2: {
            Row1: '近24小时消费：',
            Row2: '近24小时内账户内所有卡的支出+收入额',
            Row3: '（负值表示支出额>收入额，正值',
            Row4: '表示退款金额>支出金额）。',
            Row5: '近24小时新建：',
            Row6: '账户近24小时新建卡数量'
        },
       // 图表字段
        TotalSpend: '消费总额',
        Merchant: '商户'
    },
    // 虛擬卡頁
    VirtualCard: {
        // 篩選字段
        CardPlaceholder: '卡号', // 卡號
        CardholderPlaceholder: '持卡人', // 持卡人
        TagPlaceholder: '标签', // 標簽
        BinAll: '全部', // 全部
        Bin: ["全部", "222929", "428837"],
        // 表格字段
        Card: '虚拟卡', // 卡
        Expiration: '有效期', // 有效期
        SecurityCode: '安全码', // 安全碼
        BalanceIncrement: '余额 / 增值额', // 餘額 / 增值額
        Status: '状态', // 狀態
        UpdateTime: '创建时间', // 創建時間
        Operation: '操作', // 操作
        // 操作下拉框字段
        OperationSelect: {
            Increment: '增值', // 增值
            Refund: '退款', // 退款
            Logout: '注销', // 注銷
            Detail: '明细', // 明細
        },
        // 增值彈窗字段
        ModalFeild: {
            ModalTitle: '虚拟卡增值', // 虛擬卡增值
            CurrentAccountBalance: '当前账户余额', // 當前賬戶餘額
            Card: '卡号', // 卡號
            CardName: '卡主姓名', // 卡主姓名
            Tag: '标签', // 標簽
            Balance: '余额', // 餘額
            ValueAddedAmount: '增值金额', // 增值金額
            PleaseEnterTheValueAddedAmount: '请输入增值金额',
            ValueAddedRate: '增值费率', // 增值費率
            TotalExpenses: '费用总计', // 費用總計
            Cancel: '取消', // 取消
            Submit: '提交', // 提交
            AmountBz: '当前账户余额不足'
        },
        // 創建卡頁
        Create: {
            CreateANewCard: '创建新卡',
            CardName: '卡主姓名',
            InitialBalance: '初始余额',
            PleaseEnterTheInitialBalance: '请输入初始余额',
            LabelOptional: '标签 (可选)',
            PleaseEnterTheLabel: '请输入标签',
            Expenses: '费用',
            CardOpeningFee: '开卡费',
            ValueAddedExpenses: '增值费用',
            Total: '总计',
            Guojia: '美国',
            Submit: '提交',
            CardType: '卡类型', // 卡類型
            PlaceOfIssue: '发行地',
            SingleConsumptionLimit: '单笔消费限额',
            ValueAddedRate: '增值费率',
            ApplicableMerchant: '适用商户',
            InvalidAmount: '无效金额', // 無效金額
            InvalidName: '无效名字', // 無效名字
            InvalidTag: '无效标签'
        }

    },
    // 卡明細頁
    CardDetail: {
        Card: '卡号', // 卡號
        CPlaceHolder: '持卡人', // 模糊查找
        Tag: '标签', // 標簽
        Cardholder: '持卡人', // 持卡人
        Amount: '金额', // 金額
        BeforeDate: '此时间前',
        AfterDate: '此时间后',
        SelectStatus: '状态',
        SelectType: '类型',
        TypeLabel1: '授权',
        TypeLabel2: '撤销',
        TypeLabel3: '退款',
        Search: '查询', // 查詢
        Clear: '清除', // 清除
        // 表格字段
        Table: {
            ConsumptionCard: '虚拟卡', // 消費卡
            ConsumptionTime: '时间', // 消費時間
            ConsumerMerchant: '商户', // 消費商戶
            AuthorizedAmount: '授权金额', // 授權金額
            AuthorizedCurrency: '授权币种',  // 授權幣種
            SettlementAmount: '结算金额', // 結算金額
            SettlementCurrency: '结算币种', // 結算幣種
            Type: '类型', // 類型
            Status: '状态' // 狀態
        }
    },
    // 賬戶明細頁
    AccountDetail: {
        Time: '时间', // 時間
        Type: '类型', // 類型
        Amount: '金额',  // 金額
        Status: '状态', // 狀態
        Card: '虚拟卡', // 卡
        Remark: '备注', // 備注
        Description: '补充说明' // 説明
    },
    // 登录页
    LoginFields: {
        LoginAccount: '登入账户', // 登入账户
        PlaceholderEmail: '邮箱地址', // 邮箱地址
        PlacehoderPassword: '登入密码', // 登入密码
        Login: '登录', // 登录
        NextStep: '下一步',
        RegisterFields: '注册', // 注册
        FotonCardInfo: 'FotonCard为您提供安全、便捷、灵活的虚拟卡服务，覆盖多种消费场景，开卡即用，实时到帐，极速消费。'
    },
    // 注册页
    RegisterFields: {
        CreateAccount: '创建账户', // 创建账户
        CreateTip: '填写以下信息创建您的账户',
        PlaceholderEmail: '邮箱地址', // 邮箱地址
        PlaceholderEmailCode: '邮箱验证码', // 邮箱验证码
        PlacehoderPassword: '登入密码', // 登入密码
        PlacehoderConfirmPassword: '确认密码', // 确认密码
        None: '请输完整填写表格', // 不能为空
        SendCode: '重新发送',
        SendCode2: '发送验证码',
        InvitationCode: '邀请码',
        CETip: '请输入正确的邮箱',
        CRPTip: '两次密码输入不正确',
    }
}