/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2023-07-20 11:03:01
 * @LastEditors: 
 * @LastEditTime: 2023-07-20 11:05:54
 */
import { getCookie } from "./getCookie";
export function delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if(cval != null) {
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString()
    }
}