import axios from 'axios'
//这里引用了element的loading全屏加载
import { Loading } from "element-ui";
import { Message } from 'element-ui';
import router from '../router';

const service = axios.create({
 baseURL: '/',
 timeout: 20000 // 设置请求超时时间 9000 = 9s
})
let loading = "";
// let token = '_ga=GA1.1.1507004517.1689560868; _gid=GA1.1.3611500.1689560868; _gat_gtag_UA_118965717_3=1; _gat_gtag_UA_118965717_7=1; _ga_HSZXNYSSWE=GS1.1.1689559426.1.1.1689561411.60.0.0'
// 请求拦截器
service.interceptors.request.use(
 (config) => {
   console.log('config=>', config)
  //  config.headers = {"Authorization": "Bearer " + token}
  // config.url = 'https://test.fotoncard.com/request/user.php'
  
  // 在请求发送之前做一些处理
  if (!(config.headers['Content-Type'])) {
//    loading = Loading.service({
//     lock: true,
//     text: "加载中...",
//     spinner: "el-icon-loading",
//     background: "rgba(255,255,255,0.7)",
//     customClass: "request-loading",
//    });
    loading = Loading.service({ fullscreen: true })
    config.loading = true
  // if(config.loading == true) {
  //   loading.close();
  // }
    
    let formData = new FormData()
   if (config.method == 'post') {
    // config.headers['Content-Type'] =
    //  'application/json;charset=UTF-8'
    
    for (var key in config.data) {
        formData.append(key, config.data[key])
    //  if (config.data[key] === '') {
    //   delete config.data[key]
    //  }
    }
    // config.data = JSON.stringify(config.data)
    config.data = formData
   } else {
    config.headers['Content-Type'] =
     'application/x-www-form-urlencoded;charset=UTF-8'
    config.data = JSON.stringify(config.data)
   }
  }
//   const token = "token"
//   // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
//   if (token) {
//    config.headers['Authorization'] = token
//   }
  return config
 },
 (error) => {
  loading.close();
  // 发送失败
  console.log(error)
  Message.closeAll()
  return Promise.reject(error)
 }
)

// 响应拦截器
service.interceptors.response.use(
 (response) => {
     console.log('response==>', response)
     if(response.status == 200) {
      loading.close();
     }else{
      loading = Loading.service({ fullscreen: true })
     }

  // loading.close();
  // dataAxios 是 axios 返回数据中的 data
  // loadingInstance.close();
  const dataAxios = response.data
  // 这个状态码是和后端约定的
  console.log('dataAixos==>', dataAxios)


  if(dataAxios.code === 401) {
    loading.close();
    window.localStorage.clear()
    router.replace('/pages/login')
    // MessageBox.alert(dataAxios.message, "提示", {
    //     confirmButtonText: "确定",
    //     callback: () => {
    //       window.sessionStorage.clear()
    //       router.replace('/pages/login')
    //     },
    //   })
  }
  if(dataAxios.code == 200) {
    loading.close();
  }
  if(dataAxios.code == 403) {
    loading.close();
  }
  return dataAxios
 },
 (error) => {
   console.log("error ==>", error)
  if(error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")){
    loading.close()
    Message({

      message: '網絡請求超時，請重試',
      type: "error",
      duration: 5 * 1000,
      })
    
    }
    
  return Promise.reject(error)
  // return Promise.race(error)
 }
)

	export default service