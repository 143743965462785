<template>
  <router-view></router-view>
</template>

<script>
// import { changeLanguages } from "../src/api/http";
export default {
  name: "App",
  data() {
    return {
      ipn: "",
      userId: window.localStorage.getItem("uid")
    };
  },
  watch: {
    // "userId": {
    //   handler(e) {
    //     console.log("e", e)
    //     if(e) {
    //       this.$store.dispatch('openTimeTask', e)
    //     }
    //   },
    //   immediate: true
    // }
    // ipn: {
    //   handler(val) {
    //     // window.sessionStorage.getItem("ipname")
    //     console.log("ip val ==>", val);
    //     if (val == "国内未能识别的地区") {
    //       // 请求翻译接口
    //       setTimeout(() => {
    //         let obj = {
    //         type: "change_language",
    //         lan: "EN",
    //       };
    //       changeLanguages(obj).then((res) => {
    //         // console.log("lange res==>", res)
    //         if (res.code == 200) {
    //           window.localStorage.setItem("lang", "en");
    //           this.$i18n.locale = "en";
    //         } else {
    //           this.$message.error(res.message);
    //         }
    //       });
    //       }, 2000)
    //     } else {
    //       console.log("ip true");
    //     }
    //   },
    // },
  },
  created() {
    // this.ipn = window.sessionStorage.getItem("ipname");
    // let uid = window.localStorage.getItem("uid")
    // if(uid) {
    //   this.$store.dispatch('openTimeTask', uid)
    // }
  },
  mounted() {
    console.log("mounted")
    // let uid = window.localStorage.getItem("uid")
    // if(uid) {
    //   this.$store.dispatch('openTimeTask', uid)
    // }
  },
  updated() {
    console.log("update")
  },
  activated() {
    console.log("active")
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
.el-popover__title {
  text-align: center !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #00bea4 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #00bea4 !important;
}
.el-input__inner {
  border-radius: 20px !important;
  border-color: #f1f1f2 !important;
  height: 32px !important;
}
.el-input__icon{
  line-height: 33px !important;
}
.el-form-item__content {
  margin-left: 0 !important;
}
.table {
  background-color: #fff !important;
  color: #757575 !important;
  border-color: #ececed !important;
}
.table-responsive {
  height: 100vh !important;
}
.container-fluid {
  padding: 0 !important;
}
// .table-dark.table-hover tbody tr:hover {
//   color: #757575 !important;
//   background-color: #fafafa !important;
// }
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #f1f1f2 !important;
  border-width: 1px;
}
.card {
  border: 0 !important;
}
.c-sidebar.c-sidebar-fixed {
  top: 59px !important;
  border-top: 1px solid #f1f1f2;
}
.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
  position: fixed !important;
}
.mr-auto,
.mx-auto {
  margin-left: 8px !important;
}
.c-sidebar-minimized.c-sidebar-fixed {
  width: 98px !important;
  background: #fff;
  color: #999;
  border-right: 1px solid #f1f1f2;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-top: 0 !important;
}
.c-header {
  border-bottom: 1px solid #f1f1f2;
  min-height: 60px;
}
.card-header {
  border: 0 !important;
  padding-bottom: 0 !important;
  background-color: #fff !important;
  padding-left: 0.6rem;
}
.c-main {
  // padding-top: 0 !important;
  padding-top: 3.6rem !important;
  // padding-left: 1.86rem !important;
}
.card-body {
  padding: 0 !important;
  // padding-left: 0.8rem !important;
}
.c-footer {
  padding: 0 3rem !important;
}
// F1F1F2
.table td {
  vertical-align: middle !important;
  // text-align: center !important;
}
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0rem #fff !important;
  box-shadow: 0 0 0 0rem #fff !important;
}
// .table-dark.table-striped tbody tr:nth-of-type(even) {
//   background-color: #fafafa;
// }
thead {
  background: #fafafa;
}
.table-dark.table-hover tbody tr:hover {
  color: #757575 !important;
  background: #fafafa !important;
}
.table-dark.table-hover tbody tr {
  border-bottom: 1px solid #f1f1f2;
  color: #757575;
}
.card-footer {
  border: 0 !important;
}
.heih{
  height: 300px !important;
  overflow: hidden;
  overflow-y: scroll;
}
// .c-sidebar-nav{
//   flex-direction: row !important;
//   align-items: center;
// }
</style>
<style>
</style>
