import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '../store'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}



// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// const VirtualCard = () => import('@/views/virtual-card/VirtualCard')
const CardLists = () => import('@/views/virtual-card/CardLists')
const CardCreate = () => import('@/views/virtual-card/CardCreate')
const CardList = () => import('@/views/virtual-card/CardList')
const ConsumerDetails = () => import('@/views/consumer-details/ConsumerDetails')
const AccountFlow = () => import('@/views/account-flow/AccountFlow')
const Funds = () => import('@/views/funds/Funds')
const Accounts = () => import('@/views/funds/Accounts')
const InwardRem = () => import("@/views/funds/InwardRem")
const AliPay = () => import("@/views/funds/AliPay")
const BankWithdrawal = () => import("@/views/funds/BankWithdrawal")
const AliPayWithdrawal = () => import("@/views/funds/AliPayWithdrawal")
const TransferUSDT = () => import("@/views/funds/TransferUSDT")
const WithdrawUSDT = () => import("@/views/funds/WithdrawUSDT")

// 卡明细
const DetailConsumer = () => import("@/views/card-detail/ConsumerDetails")
const DetailAccount = () => import("@/views/card-detail/AccountFlow")
const Report = () => import("@/views/card-detail/Report")
// const AccountSettings = () => import('@/views/account-settings/AccountSettings')

// 代理中心
const RewardCenterAccount = () => import("@/views/reward-center/Account")
const RewardCenterRewards = () => import("@/views/reward-center/Rewards")
const RewardCenterHierarchy = () => import("@/views/reward-center/Hierarchy")
const BindWithdrawAcc = () => import("@/views/reward-center/BindWithdrawAcc")
const TransferToAccount = () => import("@/views/reward-center/TransferToAccount")
const AgentWithdraw = () => import("@/views/reward-center/AgentWithdraw")
const BindBankAcc = () => import("@/views/reward-center/BindBankAcc")
const BindUSDTAcc = () => import("@/views/reward-center/BindUSDTAcc")

// const Colors = () => import('@/views/theme/Colors')
// const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
// const Cards = () => import('@/views/base/Cards')
// const Forms = () => import('@/views/base/Forms')
// const Switches = () => import('@/views/base/Switches')
// const Tables = () => import('@/views/base/Tables')
// const Tabs = () => import('@/views/base/Tabs')
// const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
// const Carousels = () => import('@/views/base/Carousels')
// const Collapses = () => import('@/views/base/Collapses')
// const Jumbotrons = () => import('@/views/base/Jumbotrons')
// const ListGroups = () => import('@/views/base/ListGroups')
// const Navs = () => import('@/views/base/Navs')
// const Navbars = () => import('@/views/base/Navbars')
// const Paginations = () => import('@/views/base/Paginations')
// const Popovers = () => import('@/views/base/Popovers')
// const ProgressBars = () => import('@/views/base/ProgressBars')
// const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
// const StandardButtons = () => import('@/views/buttons/StandardButtons')
// const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
// const Dropdowns = () => import('@/views/buttons/Dropdowns')
// const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
// const Alerts = () => import('@/views/notifications/Alerts')
// const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const WeChatLogin = () => import('@/views/pages/WeChatLogin')
const WeChatBind = () => import('@/views/pages/WeChatBind')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
const UAccount = () => import('@/views/users/Account')
const UResetPassword = () => import('@/views/users/ResetPassword')
const UWecahtBind = () => import('@/views/users/WeChatBind')
const UMessage = () => import('@/views/users/Message')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */
 router.beforeEach((to, from, next) => {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    // window.location.href = 'https://mobiledev.fotoncard.com/#/pages/dashboard/dashboard' // dev测试
    // m.fotoncard.com
    // let agent = localStorage.getItem('agent')
    // localStorage.setItem('agent', store.state.agentp)
    // localStorage.removeItem('agent')
    // setTimeout(() => {
    //   let pa = this.$route.query.agent
    //   window.location.href = 'https://m.fotoncard.com/#/pages/register/register?agent='+pa
    // }, 3000)

    if(to.query.agent == undefined || to.query.agent == "") {
      window.location.href = 'https://m.fotoncard.com/#/pages/login/login'
      // window.location.href = 'https://dashboard.fotoncard.com/#/pages/login'
    }else{
      window.location.href = 'https://m.fotoncard.com/#/pages/register/register?agent='+to.query.agent
    }
    
    
    
    // setTimeout(() => {
    //   window.location.href = 'https://m.fotoncard.com/#/pages/register/register?agent='+to.query.agent // live
    //   // localStorage.removeItem('agent')
    // }, 3000)
    // window.location.href = 'http://localhost:8080/#/pages/dashboard/dashboard'
    return
  }
  next()
})

router.beforeEach((to, from, next) => {
  const isLogin = sessionStorage.getItem("isLogin")
  if (isLogin) {
    // next("/dashboard")
    next()
  } else {
    if (to.name != 'Register' && to.name != 'Login' && to.name != 'WeChatLogin' && to.name != 'WeChatBind') {
      next('/pages/login')
      next()
    } else {
      next()
    }
  }
})

router.beforeEach((to, from, next) =>{

  // 检查版本更新
  if(from.path !== '/') {
    checkAppNewVersion()
  }
  next()
})

// 监听页面打开显示
document.addEventListener('visibilitychange', function () {
  // console.log('show ===>', document.visibilityState, !document.hidden)
  if (!document.hidden) {
    checkAppNewVersion()
  }
})

// 检查服务端是否已经更新，如果更新则刷新页面
function checkAppNewVersion() {
  const url = `/version.json?t=${Date.now()}`
  // const url = `/dist/version.json?t=${Date.now()}`
  // let res = null
  // try {
  //   res = axios.get(url)
  // } catch (err) {
  //   console.error('checkAppNewVersion error: ', err)
  // }
  axios.get(url).then((res) => {
    console.log("res index==>", res)
  if (!res) return
    const version = res.data.version
    console.log('verion==》', version)
    // const localVersion = Local.get(localKeys.APP_VERSION)
    const localVersion = localStorage.getItem('version')
    console.log('localVersion==>', localVersion)
  if (localVersion && localVersion !== version) {
    // Local.set(localKeys.APP_VERSION, version)
    localStorage.setItem('version', version)
    window.location.reload()
  }
  // Local.set(localKeys.APP_VERSION, version)
    localStorage.setItem('version', version)
  }).catch((err) => {
    console.error('checkAppNewVersion error: ', err)
  })
  // if (!res) return
  // const version = res.data.version
  // const localVersion = Local.get(localKeys.APP_VERSION)
  // if (localVersion && localVersion !== version) {
  //   Local.set(localKeys.APP_VERSION, version)
  //   window.location.reload()
  // }
  // Local.set(localKeys.APP_VERSION, version)
}

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      // redirect: "/pages/login",
      name: 'Home',
      component: TheContainer,
      children: [
        {
          meta: {
            label: "首页",
          },
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        // 奖励中心
        {
          meta: {
            label: "奖励中心",
            isRewardCenterPage: true
          },
          path: 'reward-center',
          redirect: '/reward-center/account',
          name: 'RewardCenter',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              meta: {
                label: "代理账户",
                isRewardCenterPage: true
              },
              path: 'account',
              name: 'Account',
              component: RewardCenterAccount
            },
            {
              meta: {
                label: "奖励明细",
                isRewardCenterPage: true
              },
              path: 'rewards',
              name: 'Rewards',
              component: RewardCenterRewards
            },
            {
              meta: {
                label: "用户组织",
                isRewardCenterPage: true
              },
              path: 'hierarchy',
              name: 'Hierarchy',
              component: RewardCenterHierarchy
            },
            {
              meta: {
                label: "绑定提现账户",
                isRewardCenterPage: true
              },
              path: 'bind-withdraw-acc',
              name: 'BindWithdrawAcc',
              component: BindWithdrawAcc
            },
            {
              meta: {
                label: "转出至账户余额",
                isRewardCenterPage: true
              },
              path: 'transfer-to-account',
              name: 'TransferToAccount',
              component: TransferToAccount
            },
            {
              meta: {
                label: "代理賬戶提現",
                isRewardCenterPage: true
              },
              path: 'agent-withdraw',
              name: 'AgentWithdraw',
              component: AgentWithdraw
            },
            {
              meta: {
                label: "绑定银行账户",
                isRewardCenterPage: true
              },
              path: 'bind-bank-acc',
              name: 'BindBankAcc',
              component: BindBankAcc
            },
            {
              meta: {
                label: "绑定USDT账户",
                isRewardCenterPage: true
              },
              path: 'bind-usdt-acc',
              name: 'BindUSDTAcc',
              component: BindUSDTAcc
            }
          ]
        },
        // 卡明细
        {
          meta: {
            label: "明细",
            isDeatilPage: true
          },
          path: 'card-detail',
          redirect: '/card-detail/consumer-details',
          name: 'CardDetail',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              meta: {
                label: "虚拟卡",
                isDetailPage: true
              },
              path: 'consumer-details',
              name: 'ConsumerDetails',
              component: DetailConsumer
            },
            {
              meta: {
                label: "账户",
                isDetailPage: true
              },
              path: 'account-flow',
              name: 'AccountFlow',
              component: DetailAccount
            },
            {
              meta: {
                label: "報告",
                isDetailPage: true
              },
              path: 'report',
              name: 'Report',
              component: Report
            }
          ]
        },
        {
          meta: {
            label: "虚拟卡",
            isCardpage: true
          },
          path: 'virtual-card',
          redirect: "/virtual-card/card-list",
          name: 'VirtualCard',
          // component: VirtualCard,
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              meta: {
                label: "卡列表",
                isCardpage: true
              },
              path: 'card-list',
              name: 'CardLists',
              component: CardLists
            },
            {
              meta: {
                label: "創建",
                isCardpage: true
              },
              path: 'card-create',
              name: 'CardCreate',
              component: CardCreate
            },
          ]
        },
        // 卡創建
        // {
        //   meta: {
        //     label: "創建"
        //   },
        //   path: 'card-create',
        //   name: 'CardCreate',
        //   component: CardCreate
        // },
        {
          meta: {
            label: "資金",
            meta: {
              isFundsPage: true
            }
          },
          path: 'funds',
          name: 'Funds',
          redirect: "/funds/funds",
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
                meta: {
                  label: "资金",
                  isFundsPage: true
                },
                path: 'funds',
                name: 'Funds',
                component: Funds
            },
            {
              meta: {
                label: "账户",
                isFundsPage: true
              },
              path: 'accounts',
              name: 'Accounts',
              component: Accounts
          },
          {
            meta: {
              label: "汇入资金",
              isFundsPage: true
            },
            path: 'inwardrem',
            name: 'InwardRem',
            component: InwardRem
          },
          {
            meta: {
              label: "支付",
              isFundsPage: true
            },
            path: 'alipay',
            name: 'AliPay',
            component: AliPay
          },
          {
            meta: {
              label: "銀行提現",
              isFundsPage: true
            },
            path: 'bankwithdrawal',
            name: 'BankWithdrawal',
            component: BankWithdrawal
          },
          {
            meta: {
              label: "支付寶提現",
              isFundsPage: true
            },
            path: 'alipaywithdrawal',
            name: 'AliPayWithdrawal',
            component: AliPayWithdrawal
          },
          {
            meta: {
              label: "USDT转入",
              isFundsPage: true
            },
            path: 'transfer-usdt',
            name: 'TransferUSDT',
            component: TransferUSDT
          },
          {
            meta: {
              label: "USDT提现",
              isFundsPage: true
            },
            path: 'withdraw-usdt',
            name: 'WithdrawUSDT',
            component: WithdrawUSDT
          }
          ]
        },
        {
          meta: {
            label: "消费明细",
            isDetailPage: true
          },
          path: 'consumer-details',
          name: 'ConsumerDetails',
          component: ConsumerDetails
        },
        {
          meta: {
            label: "账户流水",
          },
          path: 'account-flow',
          name: 'AccountFlow',
          component: AccountFlow
        },
        // {
        //   meta: {
        //     label: "账户设置",
        //   },
        //   path: 'account-settings',
        //   name: 'AccountSettings',
        //   component: AccountSettings
        // },
        // {
        //   path: 'theme',
        //   redirect: '/theme/colors',
        //   name: 'Theme',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'colors',
        //       name: 'Colors',
        //       component: Colors
        //     },
        //     {
        //       path: 'typography',
        //       name: 'Typography',
        //       component: Typography
        //     }
        //   ]
        // },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'account',
              name: 'Account',
              component: UAccount
            },
            {
              path: 'message',
              name: 'Message',
              component: UMessage
            },
            {
              path: 'resetpassword',
              name: 'ResetPassword',
              component: UResetPassword
            },
            {
              path: 'wechat-bind',
              name: 'WechatBind',
              component: UWecahtBind
            },
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        // {
        //   path: 'base',
        //   redirect: '/base/cards',
        //   name: 'Base',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'cards',
        //       name: 'Cards',
        //       component: Cards
        //     },
        //     {
        //       path: 'forms',
        //       name: 'Forms',
        //       component: Forms
        //     },
        //     {
        //       path: 'switches',
        //       name: 'Switches',
        //       component: Switches
        //     },
        //     {
        //       path: 'tables',
        //       name: 'Tables',
        //       component: Tables
        //     },
        //     {
        //       path: 'tabs',
        //       name: 'Tabs',
        //       component: Tabs
        //     },
        //     {
        //       path: 'breadcrumbs',
        //       name: 'Breadcrumbs',
        //       component: Breadcrumbs
        //     },
        //     {
        //       path: 'carousels',
        //       name: 'Carousels',
        //       component: Carousels
        //     },
        //     {
        //       path: 'collapses',
        //       name: 'Collapses',
        //       component: Collapses
        //     },
        //     {
        //       path: 'jumbotrons',
        //       name: 'Jumbotrons',
        //       component: Jumbotrons
        //     },
        //     {
        //       path: 'list-groups',
        //       name: 'List Groups',
        //       component: ListGroups
        //     },
        //     {
        //       path: 'navs',
        //       name: 'Navs',
        //       component: Navs
        //     },
        //     {
        //       path: 'navbars',
        //       name: 'Navbars',
        //       component: Navbars
        //     },
        //     {
        //       path: 'paginations',
        //       name: 'Paginations',
        //       component: Paginations
        //     },
        //     {
        //       path: 'popovers',
        //       name: 'Popovers',
        //       component: Popovers
        //     },
        //     {
        //       path: 'progress-bars',
        //       name: 'Progress Bars',
        //       component: ProgressBars
        //     },
        //     {
        //       path: 'tooltips',
        //       name: 'Tooltips',
        //       component: Tooltips
        //     }
        //   ]
        // },
        // {
        //   path: 'buttons',
        //   redirect: '/buttons/standard-buttons',
        //   name: 'Buttons',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'standard-buttons',
        //       name: 'Standard Buttons',
        //       component: StandardButtons
        //     },
        //     {
        //       path: 'button-groups',
        //       name: 'Button Groups',
        //       component: ButtonGroups
        //     },
        //     {
        //       path: 'dropdowns',
        //       name: 'Dropdowns',
        //       component: Dropdowns
        //     },
        //     {
        //       path: 'brand-buttons',
        //       name: 'Brand Buttons',
        //       component: BrandButtons
        //     }
        //   ]
        // },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            // {
            //   path: 'alerts',
            //   name: 'Alerts',
            //   component: Alerts
            // },
            // {
            //   path: 'badges',
            //   name: 'Badges',
            //   component: Badges
            // },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'wechat-login',
          name: 'WeChatLogin',
          component: WeChatLogin
        },
        {
          path: 'wechat-bind',
          name: 'WeChatBind',
          component: WeChatBind
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

export default router
