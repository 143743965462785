/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-06-08 10:04:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-10-23 11:15:45
 */

import Vue from "vue";
import VueI18n from 'vue-i18n';
import zhCN from './cn';
import enUS from './en';
import zhTW from "./tw";
import elementZh from 'element-ui/lib/locale/lang/zh-CN'
import elementEN from 'element-ui/lib/locale/lang/en'
import elementZHTW from 'element-ui/lib/locale/lang/zh-TW'

Vue.use(VueI18n);

const i18n = new VueI18n({
    // 设置默认语言
    // locale: localStorage.getItem('lang') || 'tw', // 语言标识
    locale: localStorage.getItem('admin_lang') == 'TC' ? 'tw' : 'en', // 语言标识
    // locale: "zh-TW",
    // fallbackLocale: "zh-TW",
    // 添加多语言（每个语言标示对应一个语言文件）
    messages: {
        tw: {
            ...elementZHTW,
            ...zhTW
        },
        zh: {
            ...elementZh,
            ...zhCN
        },
        // zhTW,
        
        en: {
            ...elementEN,
            ...enUS
        },
    }
})
// 暴露i18n
export default i18n;