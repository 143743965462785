
export default {
    // 頂部導航欄
    navbar: {
        Dashboard: 'Dashboard',
        VirtualCard: 'Virtual Card',
        CardDetails: 'Card Transaction',
        AccountDetails: 'Account Transaction',
        AccountSettings: 'Account Setting',
        Funds: 'Funds',
        Transaction: 'Transaction',
        RewardCenter: 'Agent Center',
        WeChatMa: 'Follow Official Wechat',
        TelegramTip: 'Follow Official Telegram：',
        ClickToShowQRCode: 'Click to show QR code',
        GfLink: 'Official Channel: ',
        ClickCopy: 'click to copy',
        TelegramBeforeTip: 'Please directly scan the QR code to add the official account, beware of the fake accounts, FotonCard WILL NOT ask you for any sensitive information including card expiration, security code, account password, nor will we ask you to transfer money through social accounts!',
        TelegramBeforeTip2: 'Please beware of the fake accounts, FotonCard WILL NOT ask you for any sensitive information including card expiration, security code, account password, nor will we ask you to transfer money through social accounts!',
        TelegramTipNoted: 'Noted',
        Whatsapp: 'Follow Official WhatsApp：',
        tgqr3Tip1: 'Please scan the QR code to add our official Telegram account, or',
        tgqr3Tip2: 'click here',
        tgqrTip3: 'to start Telegram and add, ',
        tgqrTip4: 'be careful to guard against fake accounts.'
    },
    // 側邊欄
    ceNavbar: {
        CardList: 'Virtual Card', // 卡列表
        Create: 'Create', // 創建
        Funds: 'Funds', // 資金
        Accounts: 'Accounts', // 賬戶
        RewardAccount: 'Account',
        Rewards: 'Rewards',
        Hierarchy: 'Hierarchy',
        Statistics: 'Statistics',
        Report: 'Report',
    },
    // 頭像下拉框
    avatarSelect: {
        Account: 'Account', // 賬戶
        Notice: 'Announcement',  // 通知
        Message: 'Message', // 訊息
        Set: 'Setting', // 設定
        DataAuthentication: 'Account Setting', // 資料認證
        LetterMessage: 'Message',
        PaymentSettings: 'Payment Setting', // 支付設定
        Logout: 'Sign Out', // 登出
        // 賬戶設定頁
        AccountInformation: 'Account Information',
        Email: 'Email', // 電郵地址
        CreatedTime: 'Created Time', // 創建時間
        AccountStatus: 'Account Status', // 賬戶狀態
        FundAccountInformation: 'Fund Account Information', // 資金賬戶信息
        AccountName: 'Account Name', // 賬戶名
        AccountNumber: 'Account Number', // 賬戶號碼
        BankName: 'Bank Name', // 銀行名稱
        Status: 'Status', // 狀態
        AddFundAccount: 'Add Fund Account', // 新增資金賬戶
        Security: 'Security', // 安全性
        ResetPassword: 'Reset Password',
        ResetPasswordTip: 'Choose a strong password to keep your account safe',
        TwoFactorAuthentication: 'Two-factor Authentication（2FA）',
        TwoFactorTip: '2FA adds a layer of security that requires an additional authentication mehtod to log in',
        authenticationMethodEmail: '2FA authentication method',
        Method: 'Email',
        authenticationMethodEmailTip: 'Send code to your registered email address',
        WxBind: 'Wechat',
        ToBind: 'To Bind',
        Bound: 'Bound',
        Unbind: 'Unbind',
        TipMessage: 'After removing the binding of wechat, you will not be able to use wechat to scan the code for quick login. Are you sure to continue?',
        // 微信绑定页
        MaTip: 'Please use wechat to scan QR code to complete account binding',
        BSuccess: 'Success',
        // 重置密碼頁
        NewPwd: 'New Password',
        ConfirmNewPassword: 'Confirm New Password',
        EmailCode: 'Email Code',
        SendCode: 'Send Code',
        TopTip: 'The password must contain at least six letters and digits',
        BottomTip: 'A verification code will be sent to your registered email address',
        Cancel: 'Cancel',
        Confirm: 'Confirm',
        ResetSucess: 'Password reset sucess, please log in again',
        ResetSucessConfirm: 'OK',
        MessageList: 'Message List',
        GotIt: "Got it",
        ReadAll: "Read All",
        // 備用碼字段
        RecoveryCodes: 'Recovery Codes',
        CenterDesc: 'Generate a list of recovery codes you can use to log in in case you lose 2FA access.',
        Generate: 'Generate',
        // 驗證身份彈窗
        VerifyItsYou: "Verify It's You",
        VerifyItsYouTopTip: 'Continuing with this step will invalidate the recovery codes you generated previously.',
        VerifyItsYouTopTip2: 'The verification code has been sent to your registered email address. Please enter the verification code to continue.',
        VerifyCode: 'Verify Code',
        Continue: 'Continue',
        // 查看備用碼彈窗
        CopyBtn: 'Copy',
        LookCodeTip: 'Copy your recovery codes and store them somewhere safe.',
        LookCodeTip2: "If you can't receive your 2FA code for some reason, you can use recovery codes to log in. Each recovery code can only be used once.",
        CloseBtn: 'Close',
    },
    // 資金頁
    Funds: {
        WireTransfer: 'Wire Transfer(For company acc)', // 銀行電匯
        AddFunds: 'Add Funds', // 匯入資金
        AliPay: 'AliPay', // 支付寶
        TransforFunds: 'Add Funds', // 轉入資金
        Withdraw: 'Withdraw',
        Guide: 'Guide',
        CloseFundsTip: 'Bank remittance channels were temporarily closed',
        // 表格字段
        Table: {
            TransferInRecord: 'Recent Deposit', // Transfer in record
            Time: 'Time', // 时间
            Amount: 'Amount', // 金额
            FundingSource: 'Funding Source', // 转入方式
            Status: 'Status', // 状态
        },
        // 匯入資金頁
        Inwardrem: {
           AddUsdToYourAccount: "Add USD to your account", // 匯入資金至您的賬戶
           Dec: "Please remit US dollars through your fund account to the following receiving account, and the remittance will increase your account balance", // 請通過您的資金賬戶匯入美元至以下收款賬戶，匯款到賬后將增值您的賬戶餘額
           HuiRuTip: 'Only company bank account is  accepted, with a minimum deposit amount of $1,000 USD',
           HuiRuTip2: 'The following accounts only accept direct bank transfers, if you need to deposit through a third party payment channel (such as Airwallex), please contact customer service to obtain a receive account',
           Address: 'Bank Address',
           AccountName:"Account Name", // 賬戶名
           AccountNumber: "Account Number", // 賬戶號碼
           BankName: "Bank Name", // 銀行名稱
           BankCR: 'Bank Country/Region', // 銀行國家/地區
           AccountInformation: "Account Information", // 收款賬戶信息
           BankCode: "Bank Code", // 銀行代碼
           BranchCode: "Branch Code", // 分行代碼
           TipsInfo1: "This account accepts funds via Wire transfers(SWIFT) and local bank transfers,CHATS(RTGS),ACH,FPS", // 此收款账户通过电汇(SWIFT)和当地银行转账、CHATS(RTGS)、ACH、FPS接受资金
           TipsInfo2: " Funds added via local transfer have no fees and should appear within one bussiness day.Wire transfers my take 1-2 days longer", // 通过本地转账不收取任何费用，应在一个工作日内到账。 电汇则需要多1-2工作日时间
           YourFundAccount: 'Your Fund Account',
           SubmitRemittanceProof: 'Submit remittance Proof', // 提交匯款證明
           // 彈窗
           Tips: "Tip",
           TipsContent: "Please add your fund account", // 请先添加银行账户信息
           TipsContent2: 'Please complete the fund account authentication first',
           TipsButton1: "Cancel", // 取消
           TipsButton2: "Confirm", // 確認
            // 匯款證明彈窗
            AmountInUSD: 'Amount（in USD)', // 匯款金額
            AmountInUSDTip: 'Deposit amount must greater than $1000',
            RemittanceProof: 'Remittance proof', // 匯款證明
            Summit: 'Summit', // 提交
            Upload: 'Upload', // 上傳文件
            Tips: 'Submitting proof of remittance will help us expedite the process of topping up your account',
        },
        //  賬戶頁
        Accounts: {
            TipInfo: 'Transfer US dollars from your virified fund account to the designated receiving account to quickly increase your account balance',
            AccountName:"Account Name", // 賬戶名
            AccountNameTips: 'Please contact our customer service team if the account name is not correct to prevent unneccessary delays in bank transfer',
            AccountNumber: "Account Number", // 賬戶號碼
            BankName: "Bank Name", // 銀行名稱
            BankCode: "Bank Code", // 銀行代碼
            BranchCode: "Branch Code", // 分行代碼
            Status: 'Status',
            FormTip: 'Only company bank account can be verified, personal user should use the other way to deposit or withdraw',
            // 
            AddFundAccount: 'New Fund Account', // 新增资金账户
            Optional: '(Optional)',
            BankAccountCertificate: 'Bank Account Certificate', // 銀行賬戶證明
            SelectFile: 'Select File', // Select file
            FileTip: 'The bank account certificate must be consistent with the account name you filled in. It can be the bank account opening certificate and the remittance slip containing the account number and account name',
            Submit: 'Submit',
            DStatus: 'Status of current fund account :', // 當前資金賬戶審核狀態
            UploadTip1: 'Pictures can only be uploaded', // 圖片只能上傳
            UploadTip2: 'The size of the uploaded picture cannot exceed', // 上传图片大小不能超过
        }
    },
    // 支付宝支付页
    AliPay: {
        TopTip: 'Payer information will be recorded. Frequent change of payer will trigger risk limits, resulting in  payment restricted.',
        InputAmount: 'Amount（USD）',
        ExchangeRate: 'Exchange Rate',
        PaymentAmount: 'Payment Amount（CNY）',
        StartPayment: 'Start Payment',
        InputTip: 'Invalid amount',
        InputTip2: 'Deposit amount must greater than $50',
        InputTip3: 'The maximum single transfer amount is $250',
        // 弹窗
        Dialog: {
            TopTip: 'Use Alipay to scan the code for payment, and the payment will be delivered to the account in real time after successful payment',
            Close: 'Close',
            RechargeSuccessful: 'Recharge Successful'
        }
    },
    // 銀行電匯提現
    BankWithdrawal: {
        TopTip: 'The withdrawal funds will be transferred to your funds account below by bank wire transfer in 1~2 working days',
        AccountBalance: 'Account Balance',
        WithdrawAmount: 'Withdraw Amount（USD）',
        WithdrawCurrency: 'Withdraw Currency',
        ServiceFee: 'Service Fee',
        TransferAmount: 'Transfer Amount',
        Submit: 'Submit',
        InvalidAmount: 'Invalid amount'
    },
    // 支付宝提现
    AliPayWithdrawal: {
        TopTip: 'The withdrawal funds will be transferred to your Alipay payment account in 1~2 working days',
        WithdrawAccount: 'Withdraw Account',
        InputFullAccount: 'Input Full Account',
        InputFullAccountTip: 'Please enter the full account information selected above',
        Rate: 'Rate'
    },
    // USDT转入资金页
    TransferUSDT: {
        tipsp1: 'Please use TRON chain wallet to transfer to the following address',
        tipsp2: 'The system will automatically confirm the receipt on the memory block chain and post it to your    account balance',
        tipsp3: 'Do not transfer tokens other than USDT（TRC20）',
        tipsp4: 'Minimum transfer  amount: 2 USDT, amount less than 2 USDT will NOT  be transfered to your account',
        tipsp7: 'If the amount transferred is less than 100 USDT, an additional 1 USDT handling fee will be deducted. It is recommended that the amount transferred is more than 100 USDT',
        tipsp5: 'Every transfer in will be recorded on the memory block chain and cannot be tampered with',
        tipsp6: 'The transfer amount will be recharged to your account after deducting 1.5% processing fee. Example：Transfer 100 USDT, 98.5 USD will be recharged to your account',
        TransferAddress: 'Transfer Address',
        BottomTip: "You'll receive a confirmation email while the transactioin is confirmed",
        PayAfterTip: 'Users registered after July 5, 2023 must pay a refundable guarantee deposit  in order to issue cards. New users are advised to deposit at least $150 to ensure the guarantee deposit can be paid and the issue fee is covered.',
    },
    // USDT提現頁
    WithdrawUSDT: {
        Tip: 'Withdrawal USDT will be transferred to your TRON Wallet within 1~2 working days.',
        Tip2: 'A transfer fee of 1 USDT will be deducted if the withdrawal amount is less than 100',
        WithdrawAddress: 'Withdraw Address',
        WithdrawAddressTip: 'Please write Tron chain address',
        WithdrawAmount: 'Withdraw Amount',
        WithdrawCurrency: 'Withdraw Currency',
        Rate: 'Rate',
        WithdrawalFee: 'Withdrawal Fee',
        ReceivedUsdt: 'Arrival USDT',
        Submit: 'Submit'
    },
    // 奖励中心
    RewardCenter: {
        // 代理账户页
        AgentAccount: {
            AgentAccountTitle: 'Agent Account', // 代理賬戶
            Balance: 'Balance', // 餘額
            BalanceTip: 'Total reward amount - transfer out amount - withdrawal amount', // 獎勵總額 - 轉出額 - 提現額
            BalanceBtn1: 'Transfer to account balance', // 轉出至賬戶餘額
            BalanceBtn2: 'Withdrawal', // 提現
            RewardTotal: 'Total Reward', // 獎勵總額
            RewardTotalTip: 'Total amount of reward you received', // 您獲得的獎勵金額總計
            MyInvitationLink: 'My Invitation Code', // 我的邀请碼
            MyInvitationLinkTip: 'The user registered through the invitation connection or QR code is your direct push user, and the card opening fee and value-added fee generated will be calculated and awarded to you',
            CopyTip: 'Click Copy Link', // 點擊複製鏈接
            DownloadTip: 'Click to download QR code', // 點擊下載二維碼
            WithdrawalAccount: 'Withdrawal Account', // 提現賬戶
            WithdrawalAccountTip: 'Account receiving bonus withdrawal', // 接收獎勵提現之賬戶
            BindNow: 'Bind Now', // 立即綁定
            RewardProportion: 'Reward Proportion', // 獎勵比例
            AgentLevel: 'Agent Level：', // 代理級別：
            HowIncrease: 'How to increase the proportion?', // 如何提升比例？
            UserLevel: 'User Level', // 用戶層級
            CardFeeReward: 'Card Fee Reward', // 開卡費獎勵
            CardFeeRewardTip: 'Your reward = expenses incurred by the user in creating the card x proportion', // 您的獎勵 = 用戶創建卡產生之費用 X 比例
            CardTopupReward: 'Card Topup Reward',
            CardTopupRewardTip: 'Your reward = expenses incurred by the user for card appreciation x proportion', // 您的獎勵 = 用戶進行卡增值產生之費用 X 比例
            DirectPushUser: 'Invited User', // 直推用戶
            SecondaryUser: 'Secondary User', // 二級用戶
            BONUSBOOM: 'BONUS BOOM',
            REWARDTOTAL: 'Total Bonus',
            TOTALBONUSTIP: 'Total reward of bonus months',
            BONUSBOOMDESC: 'During the bonus month, your reward amount for each transaction will be increased by the corresponding percentage.'
        },
        // 綁定提現賬戶頁
        BindAccPage: {
            CardTitle: 'Bind Withdrawal Account', // 綁定提現賬戶
            BigTip: 'To ensure the security of your funds, the withdrawal account cannot be modified directly after binding. If you need to change the account, please contact customer service or send an email to',
            SmallTip: 'handled by the platform', // 由平台協助處理
            AccountType: 'Account Type', // 賬戶類型
            AliPay: 'AliPay', // 支付寶
            AccountNumber: 'Account Number', // 賬號
            AccountNumberTip: 'Enter your account number or select a payment account', // 輸入您的賬號或選擇付款賬戶
            FullAccountName: 'Full Account Name', // 完整賬戶名
            FullAccountNameTip: 'Please enter the complete account information selected above', // 請輸入上方所選的完整賬戶信息
            Confirm: 'Confirm', // 確認
        },
        // 账户明细页
        AccountDetail: {
            Time: 'Time', // 時間
            Type: 'Type', // 類型
            Amount: 'Amount', // 金額
            Status: 'Status', // 狀態
            Remark: 'Remark', // 備注
            Description: 'Description', // 描述
            Source: 'Source', // 來源
            User: 'User', // 用戶
            CardNumber: 'Card Number', // 虛擬卡
        },
        // 統計頁
        Stistic: {
            modular1: {
                MTitle: 'Users Statistic',
                Registered: 'Registered',
                Recent7DaysRegistered: 'Recent 7 Days Registered',
                PaidUsers: 'Paid Users',
                PaidUsersTip: 'The account you invited to register with card(s) created, this value will determine your agent level and reward proportion',
                Layer2Registered: 'Layer 2 Registered',
                Layer2RegisteredIn7Days: 'Layer 2 Registered In 7 Days',
                Days7: '7 Days',
                Days30: '30 Days',
                FirstLevelUser: 'Layer 1 User',
                SecondaryUser: 'Layer 2 User',
                P1: '',
                P2: ' more paid user(s) to ',
            },
            modular2: {
                MTitle: 'Reward Statistic', // Reward Statistic
                RewardTotal: 'Reward Total',
                Reward7Days: 'Reward 7 Days',
                Days7: '7 Days',
                Days30: '30 Days',
                FirstLevelReward: 'Layer 1 Reward',
                SecondaryReward: 'Layer 2 Reward'
            }
        },
        // 代理账户转出到账户余额
        TransferToAcc: {
            Title: 'Transfer to account balance',
            Tip: 'Transfer the obtained rewards to your account balance, which can be used for virtual card consumption. There is no service charge for transferring out.',
            TransferAmount: 'Amount',
            TransferConfirm: 'Confirm',
        },
        // 代理提現頁
        AgentWithdraw: {
            Title: 'Withdraw',
            Tip: 'Withdrawal funds will be transferred to your account through Alipay within 1-2 working days. The withdrawal account holder must be the same as the real name of the agent applicant. If you have any questions, please contact customer service or send an email for support',
            WithdrawAccount: 'Withdraw Account',
            Balance: 'Balance',
            WithdrawAmount: 'Withdraw Amount',
            WithdrawAmountTip: 'Minimum withdrawal amount: $50', // Minimum withdrawal amount: $50
            Diglog: 'Please bind the withdrawal account first', // Please bind the withdrawal account first
            // 
            Currency: 'Currency',
            Rate: 'Rate',
            TransferAcc: 'Transfer amount',
            AccountName: 'Account Name',
            AccountNumber: 'Account Number',
            BankName: 'Bank Name',
            BankCode: 'Bank Code',
        },
        // 綁定USDT賬戶
        BindUSDTAcc: {
            Title: 'USDT account bind',
            Tip: 'USDT withdrawal only accepts TRON chain transfer, please enter your TRON wallet address',
            Address: 'Wallet Address',
            InvalidAddress: 'Invalid Address'
        },
        // 銀行賬戶綁定
        BindBankAcc: {
            Title: 'Bank account bind',
            Tip: 'Please enter the account information consistent with the real name of the agent applicant',
            AccountName: 'Account Name',
            AccountNumber: 'Account Number',
            BankName: 'Bank Name',
            BankCode: 'Bank Code（Optional）',
            SwiftCode: 'SWIFT Code（Optional）'
        }
    },
    // 首頁
    Dashboard: {
        ClickMore: 'Click More',
        SecureYourAccount: 'Protect Your Account', // 安全保障您的數據
        CurrentBalance: 'Current Balance', // 當前餘額
        TotalEntry: 'Total Deposit', // 縂入賬
        GuaranteeDeposit: 'Guarantee Deposit',
        GuaranteeDepositTip: 'The deposit is locked to obtain additional issue amount of specified card BINs, and will be refunded to your account balance after 45 days of payment and the decline rate is less than 20%',
        HowToCalculateTheAboveData: 'How To Calculate The Above Data?', // 以上數據如何計算
        QuidckStart: 'Quick Start',
        DeclineRateTip: 'The decline rate must be less than 20% to get the guarantee deposit back',
        // Tip1: 'Current Balance: account deposit amount - total expenditure(withdrawal, service fee, card topup, etc.) . `Total Deposit: Deposit amount - Withdrawal amount.`',
        Tip1: {
            Row1: 'Current Balance:',
            Row2: 'account deposit amount - total expenditure(withdrawal, service fee, card ',
            Row3: 'topup, etc.) . ',
            Row4: 'Total Deposit: ',
            Row5: 'Deposit amount - Withdrawal amount.'
        },
        TimelyMonitorCardUsage: 'Monitor Card Usage', // monitor card usage
        ConsumptionInRecent24Hours: 'Spent In 24 Hours', // 近24小時消費
        NewInRecent24Hours: 'Created In 24 Hours', // 近24小時新建
        // Tip2: 'Spent In Recent 24 Hours: spent + refund amount of all cards in the account in recent 24 hours (negative value means spent amount > refund amount, positive value means refund amount > spent amount). `Create In Recent 24 Hours: Number of card created in the account in recent 24 hours`',
        Tip2: {
            Row1: 'Spent In Recent 24 Hours:',
            Row2: 'spent + refund amount of all cards in the account in recent 24 hours',
            Row3: '(negative value means spent amount > refund amount, positive value',
            Row4: 'means refund amount > spent amount).',
            Row5: 'Create In Recent 24 Hours:',
            Row6: 'Number of card created in the account in recent 24 hours'
        },
        // 图表字段
        ChartTitle: 'Categorized Expend',
        TotalSpend: 'Total Expend',
        Merchant: 'Merchant',
        ActiveCards: 'Active Cards',
        TheAmountOfActiveCards: 'The amount of active cards',
        TotalCardBalance: 'Total Card Balance',
        CardsTotalAvailableAmount: 'Cards total available amount',
        AuthTimes: 'Auth Times',
        AuthorizationTimes: 'Authorization times',
        DeclineRate: 'Decline Rate',
        DeclinedAuthTimes: 'Declined / Auth times'
    },
    // 虛擬卡頁
    VirtualCard: {
        // 篩選字段
        CardPlaceholder: 'Card No.', // 卡號
        CardPlaceholderOrLast4: 'Card No. or Last 4', 
        CardholderPlaceholder: 'Card Name', // 持卡人
        TagPlaceholder: 'Tag', // 標簽
        BinAll: 'All', // 全部
        Bin: ["All", "472593", "428837", "222929", "556766", "540524", "222934", "557271", "529587", "538738", "526144", "418122", "417969", "485932", "404038", "559666", "556150","450306","111111","441112","531993","534786","524897","559292","556371","519075","553370","436797"],
        // 表格字段
        Card: 'Card', // 卡
        Expiration: 'Expiration (MM/YY)', // 有效期
        SecurityCode: 'Security Code', // 安全碼
        BalanceIncrement: 'Balance / Limit', // 餘額 / 增值額
        Status: 'Status', // 狀態
        UpdateTime: 'Create Time', // 創建時間
        Operation: 'Operation', // 操作
        Limit: 'Limit',
        // proceeding彈窗字段
        ProceedingModal: {
            Card: 'Card',
            Amount: 'Amount',
            Operation: 'Operation'
        },
        // 修改标签
        Modifylabel: 'Modify Tag',
        Cancel: 'Cancel',
        Confirm: 'Confirm',
        // 操作下拉框字段
        OperationSelect: {
            Increment: 'Topup', // 增值
            Refund: 'Refund', // 退款
            Logout: 'Delete', // 注銷
            Detail: 'Transaction', // 明細
            OTP: 'OTP',
            Password3D: '3D Password',
            BillingAddr: 'Billing Address',
        },
        // 增值彈窗字段
        ModalFeild: {
            ModalTitle: 'Topup Virtual Card', // 虛擬卡增值
            CurrentAccountBalance: 'Current Account Balance', // 當前賬戶餘額
            Card: 'Card Number', // 卡號
            CardName: 'Card Holder', // 卡主姓名
            Tag: 'Tag', // 標簽
            Balance: 'Available Amount', // 餘額
            ValueAddedAmount: 'Topup Amount', // 增值金額
            PleaseEnterTheValueAddedAmount: 'topup amount',
            ValueAddedRate: 'Topup fee', // 增值費率
            TotalExpenses: 'Total Expenses', // 費用總計
            Cancel: 'Cancel', // 取消
            Submit: 'Submit', // 提交
            AmountBz: 'Not enough balance',
            RefundTip: 'Card balance must not less than $1, please delete card if you want to reund all the balance',
            RefundTip2: 'The balance after refund cannot be less than $10, please delete card if you want to reund all the balance',
            Topup556766Tip: 'The maximum card balance could not exceed $500 for 556766',
        },
        // 卡退款字段
        RefundFeild: {
            ModalTitle: 'Refund Virtual Card',
            RefundAmount: 'Refund Amount',
            PleaseEnterTheRefundAmount: 'Refund Amount',
            PleaseEnterTheRefundAmountPlus: 'Refund amaount (available amount must greater than $10 after refunding',
            tips: 'The amount will be refunded to your account balance',
            Tips2: 'Card available amount must greater than $10 after refunding, please delete card if you want to reund all the balance',
            Tips3: '4% * refund amount will be charged if (Authorization Refund Amount / Authorized Amount ) greater than 50%',
            RefundRate: 'Refund Rate',
            RefundFee: 'Refund Fee',
        },
        // 卡注销字段
        DeleteFeild: {
            ModalTitle: 'Delete Virtual Card',
            Confirm: 'Confirm',
            tips: 'The card cannot be restored after deleting and the refund will not be received. The available amount will be returned to your account.',
            ErTip: 'Payment authorization after card is deleted will result in decline records, which may result in decline fee, and high decline rate will lead to limited use of virtual cards. Please confirm that your virtual card has been untied at the merchant (Facebook e.g.）',
            Tip38738122144: 'The card cannot be cancelled due to uncleared transactions.',
        },
        // 卡统计弹窗
        StaticFeild: {
            Statistic: 'Statistic',
            OrderAddress: 'Billing Address',
            TotalTopup: 'Total Topup',
            TotalRefund: 'Total Refund',
            AuthorizedTimes: 'Authorized Times',
            AuthorizedAmount: 'Net Spent',
            DeclinedTimes: 'Declined Times',
            DeclineRate: 'Decline Rate',
            ReversedAmount: 'Reversed Amount',
            ReversRate: 'Revers Rate',
            ReversRateTip: 'Authorization Refund Amount / Authorized Amount',
            Reconciled: 'Reconciled',
            ReconciledTip: 'The system automatically deducts/recharge the difference amount (if any) after checking the total expenditure/income of the virtual card.',
            Close: 'Close'
        },
        // 支付验证码弹窗
        Otp: {
            P1: '在接收驗證碼時請務必選擇',
            P2: '郵件方式接收',
            P3: '否則將無法收到驗證碼，驗證碼一般在1 至 3分鐘内接收成功',
            P4: ' minutes ago',
            P5: 'The recent one-time passcode is: ',
            P6: 'please confirm the payment information below: ',
            Refresh: 'Refresh',
            Title: 'One-time passcode',
        },
        Password3DDC: {
            password3D: '3D Password：',
        },
        // 卡賬單地址彈窗
        CardBillingAdr: {
            TopTip: "Matching the billing address with the payer‘s information helps to pass AVS and improve payment success",
            UpdateBillingAddress: 'Update Billing Address',
            Country: 'Country',
            State: 'State',
            StateTip: 'For the US address, please fill in the two-character state code, such as "TX"',
            City: 'City',
            Address: 'Address',
            ZipCode: 'Zip Code',
            Update: 'Update',
            Close: 'Close',
        },
        // 創建卡頁
        Create: {
            CreateANewCard: 'Create A New Card',
            Guide: 'Guide',
            BinGuide: 'Confirmed Merchants',
            AllRegion: 'All Region',
            UnitedState: 'United State',
            HongKong: 'Hong Kong',
            Singapore: 'Singapore',
            UnitedKingdom: 'United Kingdom',
            AllBrand: 'All Brand',
            CreateNum: 'Remaining Limit',
            CreateNumTip: 'The issue limit of this card BIN, pay a guarantee deposit which will be refunded to your account after 45 days will immediately increase the limit, after making some transactions with cards (for review) , you could contact our customer service to apply to  increase the limit (unlimitted at most).',
            PayGuaranteeDeposit: 'Pay Guarantee Deposit',
            PleaseGuaranteeDeposit: 'Pay the Guarantee Deposit',
            // CardName: 'Card Holder',
            CardName: 'Card Name',
            CardNameTip: 'This is a bearer card, you can enter any name when paying',
            InitialBalance: 'Initial Balance',
            PleaseEnterTheInitialBalance: 'Please enter the initial balance',
            LabelOptional: 'Tag (Optional)',
            PleaseEnterTheLabel: 'Please enter the tag',
            Expenses: 'Expenses',
            CardOpeningFee: 'Card Issue Fee',
            ValueAddedExpenses: 'Topup Fee',
            Total: 'Total',
            Guojia: 'US',
            Guojia2: 'HK',
            Currency: 'Currency',
            Submit: 'Submit',
            CardType: 'Card type', // 卡類型
            PlaceOfIssue: 'Issue From',
            SingleConsumptionLimit: 'Single Spend Limit',
            ValueAddedRate: 'Topup Fee',
            ApplicableMerchant: 'Applicable Merchant',
           // 錯誤提示
            InvalidAmount: 'Invalid amount', // 無效金額
            InvalidName: 'Invalid name', // 無效名字
            InvalidTag: 'Invalid tag',
            AddOne: 'Add One',
            InitialBalanceTip: 'The minimum initial balance is $',
            BilliongAddress: 'Billing Address',
            BillingAddress485932: "Please customize the billing address in the 'Virtual Card' page after card issued, matching the billing address with the payer‘s information helps to pass AVS and improve payment success",
            ConfirmedScenario: 'Comfirmed Merchants',
            BillingAddress: 'Billing Address',
            BAddressTip: 'Please input the billing address of USA or leave the fields by default',
            City: 'City',
            State: 'State',
            ZipCode: 'Zip Code',
            InitialBalanceTip556766: 'Amout must not less than $',
            FLTip: 'First Name / Last Name length must be greater than 3',
            Than500: 'The maximum card balance could not exceed $500 for 556766',
            Than350: 'Max topup amount must less than $350',
            Than500Tip556766: 'The maximum card balance could not exceed $500 for 556766, while total spend amount is unlimited',
            Tip428837: 'Card refund is not supported for this card bin, only deleting card can refund the card balance',
            Tip472593: 'One-time passcode(OTP) supported',
            TipTip472593: 'Maximum topup amount is $50,000 in USD',
            InitialBalanceTitle: 'Fees may be incurred due to bank settlement, the balance is suggested 2% more than the actual payment amount to avoid decline transaction',
            InitialBalanceTitle2: 'Maximum topup amount is $50,000 in USD',
            Support3DTip: 'Support 3D password verification',
            SpendLimitPerYear: 'Spend limit per year: $3,000',
            Tip587_738_144_122: 'No restrictions on transaction refunds, please choose this card BIN for scenarios with high refund rates',
            Card428837: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Condition: 'Condition:Decline rate greater than 20%.',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                AuthCXTitle: '· Reversal / Refund Fee', 
                AuthCXContent1: 'Condition: After 5 authorizations for a single card, if the reverse rate is greater than 20%, every new reversal or refund transaction will be counted for fee. (Each card is calculated independently, Reverse Rate = (Reversal + Refund) / (Number of Successful Authorizations) )',
                AuthCXContent2: 'Fee: Minimum fee of $0.5 or 2% * transaction amount  in USD will be deducted from your card.',
                AuthCXContent4254: 'Fee: Minimum fee of $0.5 or 2% * transaction amount  in USD will be deducted from your account.',
                Authorizationamountless222929_222934_557271: '· Authorization amount less than $10 USD',
                AuthorizationamountlessFee222929_222934_557271: 'Fee: $0.2 USD per authorization',
            },
            Card472593: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Condition: 'Condition:Decline rate greater than 20%.',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                Authorizationamountless: '· Authorization amount less than $30 USD',
                Fee2: 'Fee: $0.4 USD per authorization',
            },
            Card556766: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Condition: 'Condition:Decline rate greater than 20%.',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                Authorizationamountless: '· Authorization amount less than $10 USD',
                Fee2: 'Fee: $0.6 USD per authorization',
                ForeignTransactionFee: '· Foreign Transaction Fee',
                ForeignTransactionFeeDec: 'For non-US merchant or non-US dollar authorization, a fee of authorization amount * 2% + $0.3 USD will be charged, which will be deducted from the balance of the virtual card'
            },
            Card485932: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Condition: 'Condition:Decline rate greater than 20%.',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                Authorizationamountless: '· Authorization amount less than $10 USD',
                Fee2: 'Fee: $0.2 USD per authorization',
                ForeignTransactionFee: '· Foreign Transaction Fee',
                ForeignTransactionFeeDec: 'For successful authorization with non-US merchant or non-US dollar currency, fee of 4% (minimum $1) of the authorized amount will be charged. The fee will be deducted from the balance of the virtual card preferentially'
            },
            Card404038_559666_556150: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Condition: 'Condition:Decline rate greater than 20%.',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                Authorizationamountless: '· Authorization amount less than $10 USD',
                Fee2: 'Fee: $0.2 USD per authorization',
                ForeignTransactionFee: '· Foreign Transaction Fee',
                ForeignTransactionFeeDec: 'For successful authorization with non-US merchant or non-US dollar currency, fee of 4% (minimum $1) of the authorized amount will be charged. The fee will be deducted from account balance',
                AuthCXContent2: 'Fee: Minimum fee of $0.5 or 2% * transaction amount in USD will be deducted from your account balance',
            },
            Card587_738_144_122: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                AuthorizationDeclinedDesc: 'The first 3 charges of a single card will be waived each month, starting from the fourth charge. $0.5 USD per declined authorization will be deducted from the balance of the card',
                AuthorizationAmount: '· Authorization amount less than $1.5 USD',
                AuthorizationAmountDesc: '$0.5 USD will be charged for each transaction that amount less than $1.5 from the 4th transaction，the fee is charged from card balance.',
                ForeignTransactionFee: '· Foreign Transaction Fee',
                ForeignTransactionFeeDesc: 'For non-HongKong merchant or non-USD authorization, 1.5% * auth amount will be charged from the card balance.',
                CardRefund: '· Card Refund',
                CardRefundDesc: 'Condition：Refund card balance to account',
                CardRefundFee: 'Fee: 3% * Refund Amount is deducted from account balance',
                IdleManagementFee: '· Idle Management Fee',
                IdleManagementFeeDesc: 'Condition: WIthout any transactions for 6 months after card issued, management fee will be deducted from the card balance every month starting from the 7th month',
                Fee: 'Fee: $2 USD / Month',
                TransactionRefund: '· Transaction Refund',
                TransactionRefundDesc: 'The first three refund transactions of a month will be $0.5 per transaction, and from the fourth refund of a month will be $1 per transaction, the fee is charged from card balance.',
            },
            Card658_556_553: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                Authorizationamountless: '· Authorization amount less than $30 USD',
                Fee2: 'Fee: $0.5 USD per authorization',
                AuthCXTitle: '· Reversal / Refund Fee', 
                AuthCXContent1: 'Condition: After 5 authorizations for a single card, if the reverse rate is greater than 20%, every new reversal or refund transaction will be counted for fee. (Each card is calculated independently, Reverse Rate = (Reversal + Refund) / (Number of Successful Authorizations) )',
                AuthCXContent2: 'Fee: Minimum fee of $0.5 or 2% * transaction amount in USD will be deducted from your account balance.',
            },
            Card25600: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Fee: 'Fee: $0.4 USD per declined authorization',
                Commonsituation: 'Common situation:Insufficient card balance',
                Authorizationamountless: '· Authorization amount less than $50 USD',
                Fee2: 'Fee: $0.5 USD per authorization',
                AuthCXTitle: '· Reversal / Refund Fee', 
                AuthCXContent1: 'Condition: After 5 authorizations for a single card, if the reverse rate is greater than 20%, every new reversal or refund transaction will be counted for fee. (Each card is calculated independently, Reverse Rate = (Reversal + Refund) / (Number of Successful Authorizations) )',
                AuthCXContent2: 'Fee: Minimum fee of $0.5 or 2% * transaction amount in USD will be deducted from your account balance.',
            },
            // 特殊卡bin
            Card491090_556167_483317_476715_447420_433451_428836_404038: {
                Otherfees: 'Other fees：',
                AuthorizationDeclined: '· Authorization Declined',
                Fee: 'Fee: $0.8 USD per declined authorization.',
                Commonsituation: 'Common situation:Insufficient card balance.',

                AuthorizationRate: '· Authorization Fee',
                Fee2: 'Fee: $0.8 USD per successful authorization.',

                AuthCXTitle: '· Reversal / Refund Fee',
                AuthCXContent1: 'Fee: Minimum fee of $1 or 18% * refund/reversal amount in USD will be deducted from your card balance.',
                
                ForeignTransactionFee: '· Foreign Transaction Fee',
                ForeignTransactionFeeDec: 'For successful authorization with non-US merchant or non-US dollar currency, fee of 4% (minimum $1) of the authorized amount will be charged. The fee will be deducted from account balance.'
            },
            // 支付保證金彈窗
            GuaranteeDialog: {
                DialogTitleTip: 'After the guarantee deposit is paid, The issue limit for the following card BINs will be immediately updated:',
                UpdateTo5: 'Update to 5 :',
                UpdateTo10: 'Update to 10 : ',
                GuaranteeDeposit: 'Guarantee Deposit：',
                DialogFooterTip1: 'The guarantee deposit will be refunded to your account balance after 45 days of payment and the decline rate is less than 20%',
                DialogFooterTip2: 'If you still need more cards, please contact our customer service, we can provide unlimited cards for users with normal trnasactions.',
                Cancel: 'Cancel',
                PayNow: 'Pay Now',
            },
            SelectedRlus: {
                Cek: 'I am aware that the following circumstances will trigger system risk controls and result in restricted accounts：',
                Rlus1: 'Pay for Telegram monthly membership with a virtual card.',
                Rlus2: 'Bind to Apple Pay with virtual card.',
                Rlus3: 'Pay on crypto currency merchants like Binance, OKEx, Coinbase, etc.',
                Rlus4: 'Excessive decline or refund rate.',
                NoRlusTip: 'Please check the warning below.',
                Rlus2Special: 'Bind to Apple Pay or Paypal with virtual card.'
            }
        }

    },
    // 卡明細頁
    CardDetail: {
        Card: ' Card No.', // 卡號
        CPlaceHolder: 'Holder', // 模糊查找
        Tag: 'Tag', // 標簽
        Cardholder: 'Name', // 持卡人
        Amount: 'Amount', // 金額
        BeforeDate: 'Before', // 选择前日期
        AfterDate: 'After', // 选择后日期
        SelectStatus: 'Status', // 请选择状态
        SelectType: 'Type', // 请选择类型
        TypeLabel1: 'Auth', // 授权
        TypeLabel2: 'Reversal', // 撤销
        TypeLabel3: 'Refund', // 退款
        Search: 'Search', // 查詢
        Clear: 'Clear', // 清除
        Guide: 'Guide',
        Deposit: 'Deposit',
        Withdraw: 'Withdraw',
        Topup: 'Topup',
        TopupFee: 'TopupFee',
        IssueFee: 'IssueFee',
        TransactionFee: 'TransactionFee',
        DeclineFee: 'DeclineFee',
        Guarantee: 'Guarantee',
        // 表格字段
        Table: {
            ConsumptionCard: 'Virtual Card', // 消費卡
            ConsumptionTime: 'Auth Time', // 消費時間
            ConsumerMerchant: 'Merchant', // 消費商戶
            AuthorizedAmount: 'Authorization', // 授權金額
            AuthorizedCurrency: 'Auth Currency',  // 授權幣種
            SettlementAmount: 'Settlement', // 結算金額
            SettlementCurrency: 'Settle Currency', // 結算幣種
            Type: 'Type', // 類型
            Status: 'Status' // 狀態
        }
    },
    // 賬戶明細頁
    AccountDetail: {
        Time: 'Time', // 時間
        Type: 'Type', // 類型
        Amount: 'Amount',  // 金額
        Status: 'Status', // 狀態
        Card: 'Card No.', // 卡
        Remark: 'Remark', // 備注
        Description: 'Description', // 説明
        FrozenWithdrawTip: 'The withdrawal amount will be marked as frozen and updated to Complete after processing',
        Statistics: {
            CardNumber: 'Card Number',
            Merchant: 'Merchant',
            Amount: 'Amount',
            Status: 'Status',
            TransactionTime: 'Transaction Time',
            Close: 'Close'
        }
    },
    // 流水交易报告
    ReportBalanceTransaction: {
        Report: 'Report',
        Item1: {
            Title: 'Balance Activity Report',
            Desc: 'Settled account transaction records, track your account balance changes',
            ReportBtn: 'Export'
        },
        Item2: {
            Title: 'Card Transaction Report',
            Desc: 'Transaction details of virtual cards',
            ReportBtn: 'Export'
        },
        ReportCardTransaction: 'Card Transaction Report',
        ReportBalance: 'Balance Activity Report',
        FileIdear: 'File format',
        NoSelectDateTip: 'Please specify the after and before time',
        Export: 'Export',
    },
    // 微信綁定頁
    WechatBind: {
        WechatLogin: 'Wechat Login',
        Tip: 'Your wechat account is not bound. Please enter the account information to complete the binding. This operation only needs to be performed once. After the binding is successful, you can use wechat to scan the code to log in. (if you do not have a fotoncard account, please',
        Register: 'Register',
        Email: 'Email',
        Password: 'Password',
        Submit: 'Submit',
        MaTip: 'Please use wechat to scan QR code to login',
        Code401: 'Wechat account is not bound, please bind it first',
        Code200: 'Success',
    },
    // 登录页
    LoginFields: {
        LoginAccount: 'Input password', // 登入账户
        PlaceholderEmail: 'Email', // 邮箱地址
        InputCode: 'Input Code',
        PlacehoderPassword: 'Password', // 登入密码
        Login: 'Sign In', // 登录
        NextStep: 'Next',
        RegisterFields: 'Register now', // 注册
        FotonCardInfo: 'FotonCard provides you with a safe, convenient and flexible virtual card service, covering a variety of consumption scenarios. You can use the card immediately after opening, receive the account in real time and consume at a high speed.',
        ForgetPassword: 'Forget password',
        PasswordOrangeTip: 'Password is encrypted, FotonCard will not know your password, please do not disclose the password to anyone', 
        // 重置密碼
        ResetPassword: 'Reset password',
        NewPassword: 'New password',
        PasswordTip: 'The password must contain at least six letters and digits',
        ConfirmPassword: 'Confirm password',
        ComfirmAndSignIn: 'Comfirm And Sign In',
        // 2fa
        Tips: 'A change in your login environment has been detected. Please enter the verification code sent to your email to complete Two-factor authentication',
        Welcome: 'Welcome to contact us: info@fotoncard.com',
        WechatScan: 'Wechat Scan',
        // 備用碼
        UseRecoveryCode: 'Use recovery code',
        RecoveryCode: 'Recovery Code',
        UseEmailCode: 'Use email code',
        InputRecoveryCode: 'Input Recovery Code',
    },
    // 注册页
    RegisterFields: {
        CreateAccount: 'Registration', // 创建账户
        CreateTip: 'Fill in the following information to create your account',
        PlaceholderEmail: 'Email', // 邮箱地址
        PlaceholderEmailCode: 'Email Code', // 邮箱验证码
        PlacehoderPassword: 'Password', // 登入密码
        PlacehoderConfirmPassword: 'Confirm Password', // 确认密码
        None: 'Please fill in full information', // 不能为空
        SendCode: 'Send Code',
        SendCode2: 'Send Code',
        InvitationCode: 'Invitation Code',
        CETip: 'Invalid email',
        CRPTip: 'Invalid password',
        PasswordTip: 'The password must contain at least six letters and digits',
        YahooTip: 'yahoo.com email registration is currently not supported, please use another email',
        // Policy 
        Accept: 'Accept',
        PrivacyPolicyand: 'Privacy Policy ',
        And: 'and ',
        CookiePolicy: 'Cookie Policy'
    },
    Footer: {
        // 如有疑問請聯係在綫客服或發送電郵至support@fotoncard.com
        EmailLink: 'If you have any questions, please contact online customer service or '
    }
}