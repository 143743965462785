import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
// import locale from 'element-ui/lib/locale'
// import elementEN from 'element-ui/lib/locale/lang/en'
// import elementZH from 'element-ui/lib/locale/lang/zh-CN'
import * as echarts from 'echarts'
import "animate.css"
import axios from "axios"
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui';
import i18n from './lang/index'
import {
  MessageBox
} from 'element-ui';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(echarts)
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.prototype.$echarts = echarts
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$axios = axios
axios.defaults.timeout = 90000

let uid = window.localStorage.getItem("uid")
let loginState = window.sessionStorage.getItem('isLogin')
if(uid && loginState) {
  store.dispatch('openTimeTask', uid)
}

if(process.env.NODE_ENV !== 'development') {
  console.log = function() {}
  console.error = function() {}
}

Vue.prototype.$$request = (config, data) => { //formTrue表示是否为formData数据
  let formData = new FormData();
    for (let it in data) {
      formData.append(it, data[it]);
    }
  return new Promise((resolve, reject) => {
    // 创建axios实例
    // let loadingInstance1 = Loading.service({
    //   fullscreen: true
    // });
    const instance = axios.create({
      // baseURL: '/request',
      timeout: 100000,
      method: 'POST',
      // header: {
      //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      // },
      data: formData
    })
    // 发送网络请求
    instance(config).then(res => {
      // loadingInstance1.close()
      resolve(res)
    }).catch(err => {
      // loadingInstance1.close()
      reject(err)
    })
  })
}

// router.beforeEach((to,from,next) => {
//   let isLogin = sessionStorage.getItem("isLogin")
//   if(to.path === "/" || to.path === "/dashboard") {
//      if(isLogin){

//     //如果存在用户信息，或者进入的页面是登录页面，则直接进入

//     next()

// }else {

//     //不存在用户信息则说明用户未登录，跳转到登录页面，带上当前的页面地址，登录完成后做回跳，

//     //如未登录用户进入用户中心的页面地址，检测到未登录，

//     //自动跳转到登录页面，并且带上用户中心的页面地址，登录完成后重新跳到个人中心页面。

//     next("/pages/login")
//     // next({

//     //   name: 'Login',

//     //   query: {

//     //     redirect: to.path

//     //   }

//     // })

// }
//   }else {
//     next()
//   }
//  if(to.path == "/pages/login") {
//    sessionStorage.clear()
//  }
 
// })

new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
