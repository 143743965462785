
export default {
    // 頂部導航欄
    navbar: {
        Dashboard: '首頁',
        VirtualCard: '虛擬卡',
        CardDetails: '卡明細',
        AccountDetails: '賬戶明細',
        AccountSettings: '賬戶設定',
        Funds: '資金',
        Transaction: '明細',
        RewardCenter: '代理中心',
        WeChatMa: '關注官方支援微信',
        TelegramTip: '關注官方支援Telegram：',
        ClickToShowQRCode: '點擊顯示二維碼',
        GfLink: '官方Telegram頻道：',
        ClickCopy: '點擊複製',
        TelegramBeforeTip: '請直接掃描二維碼添加官方賬號，謹防不法分子冒充FotonCard賬號之詐騙行爲！FotonCard不會向您索要包括卡有效期、安全碼、賬號密碼在内的任何敏感信息，更不會通過社交賬號向您要求轉賬！',
        TelegramBeforeTip2: '請謹防不法分子冒充FotonCard賬號之詐騙行爲！FotonCard不會向您索要包括卡有效期、安全碼、賬號密碼在内的任何敏感信息，更不會通過社交賬號向您要求轉賬！',
        TelegramTipNoted: '我已知悉',
        Whatsapp: '關注官方支援WhatsApp：',
        tgqr3Tip1: '請掃描二維碼添加官方Telegram賬戶，或',
        tgqr3Tip2: '點擊這裏',
        tgqrTip3: '啓動Telegram添加，',
        tgqrTip4: '請慎防虛假賬號。'
    },
    // 側邊欄
    ceNavbar: {
        CardList: '虛擬卡', // 
        Create: '創建', // 創建
        Funds: '資金', // 資金
        Accounts: '賬戶', // 賬戶
        RewardAccount: '代理賬戶',
        Rewards: '账户明細',
        Hierarchy: '用戶組織',
        Statistics: '统计',
        Report: '報告',
    },
    // 頭像下拉框
    avatarSelect: {
        Account: '賬戶', // 賬戶
        Notice: '通告',  // 通告
        Message: '訊息', // 訊息
        Set: '設定', // 設定
        DataAuthentication: '賬戶設定', // 資料認證
        LetterMessage: '收件箱',
        PaymentSettings: '支付設定', // 支付設定
        Logout: '登出', // 登出
        // 賬戶設定頁
        AccountInformation: '賬戶信息',
        Email: '電郵地址', // 電郵地址
        CreatedTime: '創建時間', // 創建時間
        AccountStatus: '賬戶狀態', // 賬戶狀態
        FundAccountInformation: '資金賬戶信息', // 資金賬戶信息
        AccountName: '賬戶名',
        AccountNumber: '賬戶號碼',
        BankName: '銀行名稱',
        Status: '狀態',
        AddFundAccount: '新增資金賬戶', // 新增資金賬戶
        Security: '安全性',
        ResetPassword: '重置密碼',
        ResetPasswordTip: '使用複雜的密碼以保障您的賬戶安全',
        TwoFactorAuthentication: '兩要素驗證',
        TwoFactorTip: '2FA 要求額外的登入方式以保障賬戶安全性',
        authenticationMethodEmail: '2FA驗證方式',
        Method: '電郵',
        authenticationMethodEmailTip: '發送驗證碼至您的注冊電郵',
        WxBind: '微信綁定',
        ToBind: '立即綁定',
        Bound: '已綁定',
        Unbind: '解除綁定',
        TipMessage: '解除微信綁定后，將無法使用微信掃碼快捷登入，確認繼續嗎？',
        // 微信绑定页
        MaTip: '請使用微信掃描二維碼完成賬戶綁定',
        BSuccess: '綁定成功',
        // 重置密碼頁
        NewPwd: '新密碼',
        ConfirmNewPassword: '確認新密碼',
        EmailCode: '電郵驗證碼',
        SendCode: '發送驗證碼',
        TopTip: '密碼至少6位並須包含英文字母和數字',
        BottomTip: '驗證碼將發送至您的注冊電郵地址',
        Cancel: '取消',
        Confirm: '確認',
        ResetSucess: '密碼重置成功，請重新登入',
        ResetSucessConfirm: '確定',
        MessageList: '私信列表',
        GotIt: "知道了",
        ReadAll: "全部已讀",
        // 備用碼字段
        RecoveryCodes: '備用碼',
        CenterDesc: '生成可用於登入的備用碼，以防您丟失2FA訪問權限。',
        Generate: '生成備用碼',
        // 驗證身份彈窗
        VerifyItsYou: '驗證身份',
        VerifyItsYouTopTip: '繼續此步驟將使您之前生成的備用碼失效。',
        VerifyItsYouTopTip2: '驗證碼已發送至您的注冊郵箱，請輸入驗證碼以繼續操作。',
        VerifyCode: '驗證碼',
        Continue: '繼續',
        // 查看備用碼彈窗
        CopyBtn: '複製',
        LookCodeTip: '複製您的備用碼並保存在安全的地方。',
        LookCodeTip2: '如果您由於某種原因無法接收2FA驗證碼，您可以使用備用碼登入，每個備用碼只能使用一次。',
        CloseBtn: '關閉',
    },
    // 資金頁
    Funds: {
        WireTransfer: '銀行電匯（公司賬戶）', // 銀行電匯
        AddFunds: '匯入資金', // 匯入資金
        AliPay: '支付寶', // 支付寶
        TransforFunds: '轉入資金', // 轉入資金
        Withdraw: '賬戶提現',
        Guide: '充值指引',
        CloseFundsTip: '銀行匯款渠道暫時關閉',
        // 表格字段
        Table: {
            TransferInRecord: '轉入記錄', // Transfer in record
            Time: '時間', // 时间
            Amount: '金額', // 金额
            FundingSource: '轉入方式', // 转入方式
            Status: '狀態', // 状态
        },
        // 匯入資金頁
        Inwardrem: {
            AddUsdToYourAccount: "匯入資金至您的賬戶", // 匯入資金至您的賬戶
            Dec: "請通過您的資金賬戶匯入美元至以下收款賬戶，匯款到賬后將增值您的賬戶餘額", // 請通過您的資金賬戶匯入美元至以下收款賬戶，匯款到賬后將增值您的賬戶餘額
            HuiRuTip: '僅接受公司銀行賬戶轉賬，最低充值金額$1,000 USD',
            HuiRuTip2: '以下賬戶只接受來自銀行的直接轉賬，若您需要經第三方支付渠道充值（如Airwallex），請聯係客戶服務取得收款賬號',
            Address: '地址',
            AccountName:"賬戶名", // 賬戶名
            AccountNumber: "賬戶號碼", // 賬戶號碼
            BankName: "銀行名稱", // 銀行名稱
            BankCR: '銀行國家/地區', // 
            AccountInformation: "收款賬戶信息", // 收款賬戶信息
            BankCode: "銀行代碼", // 銀行代碼
            BranchCode: "分行代碼", // 分行代碼
            TipsInfo1: "此收款賬戶通過電匯(SWIFT)和當地銀行轉賬、CHATS(RTGS)、ACH、FPS接受資金", // 此收款账户通过电汇(SWIFT)和当地银行转账、CHATS(RTGS)、ACH、FPS接受资金
            TipsInfo2: "通過本地轉賬不收取任何費用，應在一個工作日内到賬。 電匯則需要多1-2工作日時間", // 通过本地转账不收取任何费用，应在一个工作日内到账。 电汇则需要多1-2工作日时间
            YourFundAccount: '您的資金賬戶',
            SubmitRemittanceProof: '提交匯款證明', // 提交匯款證明
            // 彈窗
            Tips: "提示",
            TipsContent: "請先添加銀行賬戶信息", // 请先添加银行账户信息
            TipsContent2: '請先完成資金賬戶認證',
            TipsButton1: "取消", // 取消
            TipsButton2: "確認", // 確認
            // 匯款證明彈窗
            AmountInUSD: '匯款金額（美金）', // 匯款金額
            AmountInUSDTip: '最低轉入金額為$1000',
            RemittanceProof: '匯款證明', // 匯款證明
            Summit: '提交', // 提交
            Upload: '上傳文件', // 上傳文件
            Tips: '提交匯款證明有助於我們加快爲您處理賬戶充值'
         },
         //  賬戶頁
        Accounts: {
            TipInfo: '您可使用驗證通過的資金賬戶匯款美元至指定收款賬戶，快速增值您的賬戶餘額',
            AccountName:"賬戶名", // 賬戶名
            AccountNameTips: "若賬戶名不正確，請儘快聯係客戶服務修正，以免影響銀行轉賬校驗",
            AccountNumber: "賬戶號碼", // 賬戶號碼
            BankName: "銀行名稱", // 銀行名稱
            BankCode: "銀行代碼", // 銀行代碼
            BranchCode: "分行代碼", // 分行代碼
            Status: '狀態',
            FormTip: '僅接收公司銀行賬戶，個人用戶請使用其他方式充值 / 提現',
            // 
            AddFundAccount: '新增資金賬戶', // 新增资金账户
            Optional: '(選填)',
            BankAccountCertificate: '銀行賬戶證明', // 銀行賬戶證明
            SelectFile: '選取文件', // Select file
            FileTip: '銀行賬戶證明文件須與您填寫的賬戶名一致，可以為銀行開戶證明、包含賬號及戶名的匯款單',
            Submit: '提交',
            DStatus: '當前資金賬戶審核狀態: ', // 當前資金賬戶審核狀態
            UploadTip1: '圖片只能上傳', // 圖片只能上傳
            UploadTip2: '上传图片大小不能超过', // 上传图片大小不能超过
        }
    },
    // 支付宝支付页
    AliPay: {
        TopTip: '付款人信息將會被記錄，頻繁更換付款人將觸發風險限制，導致支付功能受限，請勿違規操作',
        InputAmount: '轉入金額（USD）',
        ExchangeRate: '實時匯率',
        PaymentAmount: '支付金額（CNY）',
        StartPayment: '開始付款',
        InputTip: '請輸入正確金額',
        InputTip2: '最低轉入金額為$50',
        InputTip3: '單筆最大轉入金額為$250',
        // 弹窗
        Dialog: {
            TopTip: '請使用支付寶掃碼付款，付款成功后實時到賬',
            Close: '關 閉',
            RechargeSuccessful: '充值成功'
        }
    },
    // 銀行電匯提現
    BankWithdrawal: {
        TopTip: '提現資金將在1~2工作日内通過銀行電匯方式匯入您的以下資金賬戶：',
        AccountBalance: '當前賬戶餘額',
        WithdrawAmount: '提現金額（USD）',
        WithdrawCurrency: '提現幣種',
        ServiceFee: '提現手續費',
        TransferAmount: '到賬金額',
        Submit: '提交',
        InvalidAmount: '無效金額'
    },
    // 支付宝提现
    AliPayWithdrawal: {
        TopTip: '提現資金將在1~2工作日内通過支付寶轉帳至您的付款賬戶',
        WithdrawAccount: '收款賬戶',
        InputFullAccount: '完整支付寶賬號',
        InputFullAccountTip: '請輸入上方所選的完整賬戶信息',
        Rate: '匯率'
    },
    // USDT转入资金页
    TransferUSDT: {
        tipsp1: '請使用TRON鏈錢包轉賬至以下地址',
        tipsp2: '系統將自動在區塊鏈上確認到賬情況並入賬至您的賬戶餘額',
        tipsp3: '請勿轉入USDT（TRC20）以外代幣',
        tipsp4: '最低轉入金額2 USDT，低於2 USDT的轉入將不會被處理',
        tipsp7: '轉入金額低於100 USDT的，將額外扣除1 USDT轉賬處理費，建議轉入金額大於100 USDT',
        tipsp5: '您的每筆轉入都會被記錄在區塊鏈上並不可篡改',
        tipsp6: '轉賬之USDT將會扣除1.5%資金處理手續費后入賬至您的賬戶，舉例：轉賬100 USDT = 賬戶到賬 98.5 USD',
        TransferAddress: '轉入地址',
        BottomTip: '入賬成功后您將收到郵件通知',
        PayAfterTip: '2023年7月5號后注冊的用戶須支付保證金后（可退回）才能創建卡，建議新用戶至少充值$150，以確保能繳納保證金及支付創建卡手續費。',
    },
    // USDT提現頁
    WithdrawUSDT: {
        Tip: '提現資金將在1~2工作日内轉帳至您提交的TRON錢包地址',
        Tip2: '提現金額小於100將扣除1 USDT的轉賬手續費',
        WithdrawAddress: '提現地址',
        WithdrawAddressTip: '請填寫TRON鏈地址',
        WithdrawAmount: '提現金額',
        WithdrawCurrency: '提現幣種',
        Rate: '匯率',
        WithdrawalFee: '提現手續費',
        ReceivedUsdt: '到賬USDT',
        Submit: '提交'
    },
    // 代理中心
    RewardCenter: {
        // 代理账户页
        AgentAccount: {
            AgentAccountTitle: '代理賬戶',
            Balance: '餘額',
            BalanceTip: '獎勵總額 - 轉出額 - 提現額',
            BalanceBtn1: '轉出至賬戶餘額',
            BalanceBtn2: '提現',
            RewardTotal: '獎勵總額',
            RewardTotalTip: '您獲得的獎勵金額總計',
            MyInvitationLink: '我的邀请碼',
            MyInvitationLinkTip: '通過邀請鏈接或二維碼注冊之用戶為您的直推用戶，其所產生之開卡費、增值費將計算獎勵發放給您',
            CopyTip: '點擊複製鏈接',
            DownloadTip: '點擊下載二維碼',
            WithdrawalAccount: '提現賬戶',
            WithdrawalAccountTip: '接收獎勵提現之賬戶',
            BindNow: '立即綁定',
            RewardProportion: '獎勵比例',
            AgentLevel: '代理級別：',
            HowIncrease: '如何提升比例？',
            UserLevel: '用戶層級',
            CardFeeReward: '開卡費獎勵',
            CardFeeRewardTip: '您的獎勵 = 用戶創建卡產生之費用 X 比例',
            CardTopupReward: '卡增值獎勵',
            CardTopupRewardTip: '您的獎勵 = 用戶進行卡增值產生之費用 X 比例',
            DirectPushUser: '直推用戶',
            SecondaryUser: '二級用戶',
            BONUSBOOM: '爆金行動',
            REWARDTOTAL: '爆金總額',
            TOTALBONUSTIP: '您在爆金行動中獲得的獎勵總額',
            BONUSBOOMDESC: '在指定月份内，您獲得的每筆獎勵金額都額外上浮對應比例。'
        },
        // 綁定提現賬戶頁
        BindAccPage: {
            CardTitle: '綁定提現賬戶', // 綁定提現賬戶
            BigTip: '為保障您的資金安全，提現賬戶綁定后無法直接修改，若需要變更賬戶請聯係客戶服務，或發送電郵至',
            SmallTip: '由平台協助處理', // 由平台協助處理
            AccountType: '賬戶類型', // 賬戶類型
            AliPay: '支付寶', // 支付寶
            AccountNumber: '賬號', // 賬號
            AccountNumberTip: '輸入您的賬號或選擇付款賬戶', // 輸入您的賬號或選擇付款賬戶
            FullAccountName: '完整賬戶名', // 完整賬戶名
            FullAccountNameTip: '請輸入上方所選的完整賬戶信息', // 請輸入上方所選的完整賬戶信息
            Confirm: '確認', // 確認
            

        },
        // 账户明细页
        AccountDetail: {
            Time: '時間', // 時間
            Type: '類型', // 類型
            Amount: '金額', // 金額
            Status: '狀態', // 狀態
            Remark: '備注', // 備注
            Description: '補充信息', // 描述
            Source: '來源', // 來源
            User: '用戶', // 用戶
            CardNumber: '虛擬卡', // 虛擬卡
        },
        // 統計頁
        Stistic: {
            modular1: {
                MTitle: '用戶增長統計',
                Registered: '已邀請注冊總數',
                Recent7DaysRegistered: '近7日新增注冊',
                PaidUsers: '有效付費用戶',
                PaidUsersTip: '您邀請注冊並已創建虛擬卡的用戶，此值將決定您的代理級別與獎勵比例',
                Layer2Registered: '二級用戶數',
                Layer2RegisteredIn7Days: '近7日二級用戶數',
                Days7: '7日',
                Days30: '一月',
                FirstLevelUser: '直推用戶',
                SecondaryUser: '二級用戶',
                P1: '仍需',
                P2: '個付費用戶升級為',
            },
            modular2: {
                MTitle: '獎勵統計', // Reward Statistic
                RewardTotal: '獎勵總額',
                Reward7Days: '近7日獎勵',
                Days7: '7日',
                Days30: '一月',
                FirstLevelReward: '直推獎勵',
                SecondaryReward: '二級獎勵'
            }
        },
        // 代理账户转出到账户余额
        TransferToAcc: {
            Title: '轉出至賬戶餘額',
            Tip: '將獲得的獎勵轉出至您的賬戶餘額，可用於虛擬卡消費，轉出無任何手續費。',
            TransferAmount: '轉出金額',
            TransferConfirm: '確認',
        },
        // 代理提現頁
        AgentWithdraw: {
            Title: '提現',
            Tip: '提現資金將在1~2工作日内通過支付寶轉帳至您的賬戶，提現賬戶持有人必須與代理申請人真實姓名一致，若有疑問請聯係客戶服務或發送電郵尋求支援',
            WithdrawAccount: '提現賬戶',
            Balance: '餘額',
            WithdrawAmount: '提現金額',
            WithdrawAmountTip: '最低提現金額為$50', // Minimum withdrawal amount: $50
            Diglog: '請先綁定提現賬戶', // Please bind the withdrawal account first
            // 
            Currency: '提現幣種',
            Rate: '匯率',
            TransferAcc: '轉賬金額',
            AccountName: '賬戶名',
            AccountNumber: '賬戶號碼',
            BankName: '銀行名稱',
            BankCode: '銀行代碼',
        },
        // USDT賬戶綁定
        BindUSDTAcc: {
            Title: 'USDT賬戶綁定',
            Tip: 'USDT提現仅接受TRON鏈轉賬，請輸入您的TRON錢包地址',
            Address: '錢包地址',
            InvalidAddress: '無效地址'
        },
        // 銀行賬戶綁定
        BindBankAcc: {
            Title: '銀行賬戶綁定',
            Tip: '請輸入與代理申請人真實姓名一致之賬戶信息',
            AccountName: '賬戶名',
            AccountNumber: '賬戶號碼',
            BankName: '銀行名稱',
            BankCode: '银行代碼（可選）',
            SwiftCode: 'SWIFT Code（可選）'
        }
    },
    // 首頁
    Dashboard: {
        ClickMore: '點擊查看更多',
        SecureYourAccount: '安全保障您的賬戶', // 安全保障您的數據
        CurrentBalance: '當前餘額', // 當前餘額
        TotalEntry: '縂入賬', // 縂入賬
        GuaranteeDeposit: '保證金',
        GuaranteeDepositTip: '鎖定保證金能獲得指定卡BIN額外開卡量，支付45天后且拒付率低於20%，保證金將退回至您的賬戶餘額。',
        HowToCalculateTheAboveData: '以上數據如何計算?', // 以上數據如何計算
        QuidckStart: '瞭解如何快速開始',
        DeclineRateTip: '拒付率須低於20%才能領回保證金。',
        // Tip1: '當前餘額：充值總額 - 支出總額（包括提現、手續費、卡充值等）`縂入賬：充值總額 - 提現總額`',
        Tip1: {
            Row1: '當前餘額：',
            Row2: '充值總額 - 支出總額（包括提現、手續費、',
            Row3: '卡充值等） ',
            Row4: '縂入賬: ',
            Row5: '充值總額 - 提現總額。'
        },
        TimelyMonitorCardUsage: '及時監控用卡情況', // Timely monitor card usage
        ConsumptionInRecent24Hours: '近24小時消費', // 近24小時消費
        NewInRecent24Hours: '近24小時新建', // 近24小時新建
        // Tip2: '近24小時消費：賬戶内所有卡近24小時支出 + 收入（負值表示支出額 > 收入額，正值表示收入額 > 支出額）`近24小時新建：賬戶近24小時新建卡數量`',
        Tip2: {
            Row1: '近24小時消費：',
            Row2: '賬戶内所有卡近24小時支出 + 收入',
            Row3: '負值表示支出額 > 收入額，正值',
            Row4: '表示收入額 > 支出額）。',
            Row5: '近24小時新建：',
            Row6: '賬戶近24小時新建卡數量'
        },
         // 图表字段
        ChartTitle: '支出概覽',
        TotalSpend: '支出總額',
        Merchant: '商戶',
        ActiveCards: 'Active卡數',
        TheAmountOfActiveCards: '使用中的虛擬卡數量',
        TotalCardBalance: '卡餘額總值',
        CardsTotalAvailableAmount: '虛擬卡餘額總和',
        AuthTimes: '授權次數',
        AuthorizationTimes: '消費授權次數總和',
        DeclineRate: '授權失敗率',
        DeclinedAuthTimes: '授權失敗佔授權次數比例'
    },
    // 虛擬卡頁
    VirtualCard: {
        // 篩選字段
        CardPlaceholder: '卡號', // 卡號
        CardPlaceholderOrLast4: '卡號或后4位', 
        CardholderPlaceholder: '持卡人', // 持卡人
        TagPlaceholder: '標簽', // 標簽
        BinAll: '全部', // 全部
        Bin: ["全部", "472593", "428837", "222929", "556766", "540524", "222934", "557271", "529587", "538738", "526144", "418122", "417969", "485932", "404038", "559666", "556150","450306","111111","441112","531993","534786","524897","559292","556371","519075","553370","436797"],
        // 表格字段
        Card: '虛擬卡', // 卡
        Expiration: '有效期（MM/YY）', // 有效期
        SecurityCode: '安全碼', // 安全碼
        BalanceIncrement: '餘額 / 增值縂額', // 餘額 / 增值額
        Status: '狀態', // 狀態
        UpdateTime: '創建時間', // 創建時間
        Operation: '操作', // 操作
        // 修改标签
        Modifylabel: '修改標簽',
        Cancel: '取消',
        Confirm: '確認',
        Limit: '增值額',
        // proceeding彈窗字段
        ProceedingModal: {
            Card: '卡號',
            Amount: '金額',
            Operation: '操作'
        },
        // 操作下拉框字段
        OperationSelect: {
            Increment: '增值', // 增值
            Refund: '退款', // 退款
            Logout: '注銷', // 注銷
            Detail: '明細', // 明細
            OTP: '支付驗證碼',
            Password3D: '3D 密碼',
            BillingAddr: '賬單地址',
        },
        // 增值彈窗字段
        ModalFeild: {
            ModalTitle: '虛擬卡增值', // 虛擬卡增值
            CurrentAccountBalance: '當前賬戶餘額', // 當前賬戶餘額
            Card: '卡號', // 卡號
            CardName: '卡主姓名', // 卡主姓名
            Tag: '標簽', // 標簽
            Balance: '可用餘額', // 餘額
            ValueAddedAmount: '增值金額', // 增值金額
            PleaseEnterTheValueAddedAmount: '請輸入增值金額',
            ValueAddedRate: '增值費率', // 增值費率
            TotalExpenses: '費用總計', // 費用總計
            Cancel: '取消', // 取消
            Submit: '提交', // 提交
            AmountBz: '當前賬戶餘額不足',
            RefundTip: '卡餘額不可小於$1，若要退回所有餘額，請執行“注銷”操作',
            RefundTip2: '退款后余额不能小于$10，若要退回所有餘額，請執行“注銷”操作',
            Topup556766Tip: '556766卡BIN餘額不能大於$500',
        },
        // 卡退款字段
        RefundFeild: {
            ModalTitle: '虛擬卡退款',
            RefundAmount: '退款金額',
            PleaseEnterTheRefundAmount: '請輸入退款金額',
            PleaseEnterTheRefundAmountPlus: '請輸入退款金額，退款后可用餘額必須大於 $10',
            tips: '退款金額將退回至您的賬戶餘額',
            Tips2: '退款后可用餘額必須大於 $10，若要退回所有餘額，請執行“注銷”操作',
            Tips3: '授權後退款總金額 / 授權成功總金額高於50%，將收取 4% * 退款金額手續費',
            RefundRate: '退款费率',
            RefundFee: '退款費用',
        },
        // 卡注销字段
        DeleteFeild: {
            ModalTitle: '虛擬卡注銷',
            Confirm: '確認',
            tips: '虛擬卡注銷后不能恢復，無法接收消費退款，卡内餘額將退回至您的賬戶，確認要繼續嗎？',
            ErTip: '卡注銷后的消費授權將產生拒付記錄，可能產生拒付手續費，過高的拒付率將導致虛擬卡使用受限，請確認您的虛擬卡已在商戶解綁（如Facebook）',
            Tip38738122144: '受發卡機構限制，若存在未清算完成的交易無法注銷卡。',
        },
        // 卡统计弹窗
        StaticFeild: {
            Statistic: '統計',
            OrderAddress: '賬單地址',
            TotalTopup: '增值總額',
            TotalRefund: '退款總額',
            AuthorizedTimes: '授權次數',
            AuthorizedAmount: '净支出',
            DeclinedTimes: '授權失敗次數',
            DeclineRate: '授權失敗率',
            ReversedAmount: '消費退款總額',
            ReversRate: '授權退款率',
            ReversRateTip: '授權後退款金額 / 縂授權成功金額',
            Reconciled: '校正',
            ReconciledTip: '系統校對虛擬卡支出 / 收入總值后，自動扣除 / 補充差額（若有）',
            Close: '關閉'
        },
        // 支付验证码弹窗
        Otp: {
            P1: '在接收驗證碼時請務必選擇',
            P2: '郵件方式接收',
            P3: '否則將無法收到驗證碼，驗證碼一般在1 至 3分鐘内接收成功',
            P4: '分鐘前',
            P5: '最近一次接收的支付驗證碼：',
            P6: '請確認以下支付信息是您本人操作：',
            Refresh: '刷新',
            Title: '支付驗證碼'
        },
        Password3DDC: {
            password3D: '此卡3D密碼為：',
        },
        // 卡賬單地址彈窗
        CardBillingAdr: {
            TopTip: '賬單地址與交易者信息一致有助於通過AVS校驗，提高支付成功率',
            UpdateBillingAddress: '更新賬單地址',
            Country: '國家',
            State: '省份/州',
            StateTip: '美國地址請填寫二字符州碼，如"TX"',
            City: '城市',
            Address: '地址',
            ZipCode: '郵編',
            Update: '更新',
            Close: '關閉',
        },
        // 創建卡頁
        Create: {
            CreateANewCard: '創建新卡',
            Guide: '創建指引',
            BinGuide: '協助選擇',
            AllRegion: '所有地區',
            UnitedState: '美國',
            HongKong: '香港',
            Singapore: '新加坡',
            UnitedKingdom: '英國',
            AllBrand: '所有品牌',
            CreateNum: '創建數量限制',
            CreateNumTip: '本卡BIN可創建的卡數量，支付保證金（可退回）將即時提高數量限制，使用卡進行多筆交易后，可聯係客戶服務申請提高數量，合規用卡最高可享無限數量。',
            PayGuaranteeDeposit: '支付保證金',
            PleaseGuaranteeDeposit: '請先支付保證金',
            CardName: '卡主姓名',
            CardNameTip: '此卡為不記名卡，支付時可輸入任意姓名',
            InitialBalance: '初始餘額',
            PleaseEnterTheInitialBalance: '請輸入初始餘額',
            LabelOptional: '標簽 (可選)',
            PleaseEnterTheLabel: '請輸入標簽',
            Expenses: '費用',
            CardOpeningFee: '開卡費 ',
            ValueAddedExpenses: '增值費用',
            Total: '總計',
            Guojia: '美國',
            Guojia2: '香港',
            Currency: '幣種',
            Submit: '提交',
            CardType: '卡類型', // 卡類型
            PlaceOfIssue: '發行地',
            SingleConsumptionLimit: '單筆消費限額',
            ValueAddedRate: '增值費率',
            ApplicableMerchant: '適用商戶',
            InvalidAmount: '無效金額', // 無效金額
            InvalidName: '無效名字', // 無效名字
            InvalidTag: '無效標簽',
            AddOne: '繼續增加',
            InitialBalanceTip: '最低初始餘額為$',
            BilliongAddress: '賬單地址',
            ConfirmedScenario: '用戶實測',
            BillingAddress: '賬單地址',
            BillingAddress485932: '請創建卡后在[虛擬卡]界面定制賬單地址，賬單地址與交易者信息一致有助於通過AVS校驗，提高支付成功率',
            BAddressTip: '請輸入美國地址信息或使用默認地址',
            City: '城市',
            State: '州',
            ZipCode: '郵編',
            InitialBalanceTip556766: '初始餘額不能小於$',
            FLTip: 'First Name / Last Name 至少需要4个字符',
            Than500: '556766卡BIN餘額不能大於$500',
            Than350: '虛擬卡餘額不能超過 $350',
            Than500Tip556766: '556766卡BIN餘額不能大於$500，消費總額無上限（可重複充值消費）',
            Tip428837: '此卡BIN不提供虛擬卡退款功能，僅支持注銷卡進行退款！',
            Tip472593: '可接收支付驗證碼',
            TipTip472593: '卡增值金額上限為$50,000 USD',
            InitialBalanceTitle: '銀行閒清算可能產生手續費，建議卡内餘額比實際支付金額多2%美金，以避免拒付',
            InitialBalanceTitle2: '卡增值金額上限為$50,000 USD',
            Support3DTip: '支持3D密碼驗證',
            SpendLimitPerYear: '年支付限额：$3,000 USD',
            Tip587_738_144_122: '不限制消費退款，退款率高的場景請選擇此卡BIN',
            Card428837: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Condition: '發生條件：授權失敗率達到20%后，新發生的授權失敗訂單（失敗率降至20%以下則不再收取）；失敗率 = 授權失敗次數 / 授權總數。',
                Fee: '費用：每次新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                AuthCXTitle: '· 授權撤銷 / 交易退款手續費', 
                AuthCXContent1: '發生條件（每張卡獨立計算）：授權滿5次，授權撤銷 + 交易退款次數比率達到20%（比率 =（ 授權撤銷 + 交易退款） / 授權成功次數））',
                AuthCXContent2: '費用：每筆新的授權撤銷或交易退款，收取交易金額 * 2% USD（最低$0.5 USD)，從卡餘額中扣除',
                Authorizationamountless222929_222934_557271: '· 授權金額低於 $10 USD',
                AuthorizationamountlessFee222929_222934_557271: '費用：$0.2 USD / 筆授權',
            },
            Card472593: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Condition: '發生條件：授權失敗率達到20%后，新發生的授權失敗訂單（失敗率降至20%以下則不再收取）；失敗率 = 授權失敗次數 / 授權總數。',
                Fee: '費用：每筆新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                Authorizationamountless: '· 授權金額低於 $30 USD',
                Fee2: '費用：$0.4 USD / 筆授權',
            },
            Card556766: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Condition: '發生條件：授權失敗率達到20%后，新發生的授權失敗訂單（失敗率降至20%以下則不再收取）；失敗率 = 授權失敗次數 / 授權總數。',
                Fee: '費用：每筆新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                Authorizationamountless: '· 授權金額低於 $10 USD',
                Fee2: '費用：$0.6 USD / 筆授權',
                ForeignTransactionFee: '· 跨境授權手續費',
                ForeignTransactionFeeDec: '非美國商戶或非美元授權，收取授權金額 * 2% + $0.3 USD手續費，費用從虛擬卡餘額扣除'
            },
            Card485932: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Condition: '發生條件：授權失敗率達到20%后，新發生的授權失敗訂單（失敗率降至20%以下則不再收取）；失敗率 = 授權失敗次數 / 授權總數。',
                Fee: '費用：每筆新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                Authorizationamountless: '· 授權金額低於 $10 USD',
                Fee2: '費用：$0.2 USD / 筆授權',
                ForeignTransactionFee: '· 跨境授權手續費',
                ForeignTransactionFeeDec: '非美國商戶或非美元幣種成功授權，收取授權金額 * 4%（最少$1）手續費，費用優先從虛擬卡餘額扣除'
            },
            Card404038_559666_556150: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Condition: '發生條件：授權失敗率達到20%后，新發生的授權失敗訂單（失敗率降至20%以下則不再收取）；失敗率 = 授權失敗次數 / 授權總數。',
                Fee: '費用：每筆新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                Authorizationamountless: '· 授權金額低於 $10 USD',
                Fee2: '費用：$0.2 USD / 筆授權',
                ForeignTransactionFee: '· 跨境授權手續費',
                ForeignTransactionFeeDec: '非美國商戶或非美元幣種成功授權，收取授權金額 * 4%（最少$1）手續費，費用從賬戶餘額扣除',
                AuthCXContent2: '費用：每筆新的授權撤銷或交易退款，收取交易金額 * 2% USD（最低$0.5 USD)，從賬戶餘額中扣除',
            },
            Card587_738_144_122: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                AuthorizationDeclinedDesc: '每月豁免單卡前3次收費，由第4次開始收費，$ 0.5 USD / 筆，從卡餘額中扣除',
                AuthorizationAmount: '· 網上小額交易',
                AuthorizationAmountDesc: '當月纍計超過3筆小於$1.5 USD的小額交易，從第4筆開始每筆小額交易收取$0.5 USD手續費',
                ForeignTransactionFee: '· 境外授權手續費',
                ForeignTransactionFeeDesc: '非香港地區商戶或非美元授權，收取授權金額 * 1.5 % USD 手續費，費用從虛擬卡餘額扣除',
                CardRefund: '· 卡退款',
                CardRefundDesc: '發生條件：卡餘額退回至賬戶餘額',
                CardRefundFee: '費用：3% * 退款金額，從賬戶餘額中扣除',
                IdleManagementFee: '· 休眠管理費',
                IdleManagementFeeDesc: '發生條件：購卡後連續6個月無任何交易活動，將於第7個月開始每月從卡餘額扣除',
                Fee: '費用：$ 2 USD / 月，從卡餘額中扣除',
                TransactionRefund: '· 消費退款手續費',
                TransactionRefundDesc: '商户消费成功后退款回卡内，当月前3笔是$0.5/笔，当月第4笔开始为$1/笔。',
            },
            Card658_556_553: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Fee: '費用：每筆新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                Authorizationamountless: '· 授權金額低於 $30 USD',
                Fee2: '費用：$0.5 USD / 筆授權',
                AuthCXTitle: '· 授權撤銷 / 交易退款手續費', 
                AuthCXContent1: '發生條件（每張卡獨立計算）：授權滿5次，授權撤銷 + 交易退款次數比率達到20%（比率 =（ 授權撤銷 + 交易退款） / 授權成功次數））',
                AuthCXContent2: '費用：每筆新的授權撤銷或交易退款，收取交易金額 * 2% USD（最低$0.5 USD)，從卡餘額中扣除',
            },
            Card25600: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Fee: '費用：每筆新的授權失敗收取 $0.4 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',
                Authorizationamountless: '· 授權金額低於 $50 USD',
                Fee2: '費用：$0.5 USD / 筆授權',
                AuthCXTitle: '· 授權撤銷 / 交易退款手續費', 
                AuthCXContent1: '發生條件（每張卡獨立計算）：授權滿5次，授權撤銷 + 交易退款次數比率達到20%（比率 =（ 授權撤銷 + 交易退款） / 授權成功次數））',
                AuthCXContent2: '費用：每筆新的授權撤銷或交易退款，收取交易金額 * 2% USD（最低$0.5 USD)，從卡餘額中扣除',
            },
            // 特殊卡bin
            Card491090_556167_483317_476715_447420_433451_428836_404038: {
                Otherfees: '其他涉及費用：',
                AuthorizationDeclined: '· 授權失敗費用',
                Fee: '費用：每筆新的授權失敗收取 $0.8 USD ，從賬戶中扣除',
                Commonsituation: '常見授權失敗情況：虛擬卡可用餘額不足',

                AuthorizationRate: '· 授權手續費',
                Fee2: '費用：$0.8 USD / 筆成功授權，授權失敗不收取。',

                AuthCXTitle: '· 授權撤銷 / 交易退款手續費',
                AuthCXContent1: '費用：退款/撤銷金額 * 18% USD（最低$1 USD)，優先從卡餘額中扣除。',
                
                ForeignTransactionFee: '· 跨境授權手續費',
                ForeignTransactionFeeDec: '非美國商戶或非美元幣種成功授權，收取授權金額 * 4%（最少$1）手續費，費用從賬戶餘額扣除。'
            },
            // 支付保證金彈窗
            GuaranteeDialog: {
                DialogTitleTip: '支付保證金后，您的賬戶即時更新以下卡BIN創建卡數量：',
                UpdateTo5: '數量更新為 5 ：',
                UpdateTo10: '數量更新為 10 ：',
                GuaranteeDeposit: '保證金金額：',
                DialogFooterTip1: '支付45天后且拒付率低於20%，保證金將退回至您的賬戶餘額。',
                DialogFooterTip2: '若您仍需要更多開卡數量，請聯係我們的客戶服務，我們對使用合規的用戶最高可提供無限開卡數量。',
                Cancel: '取消',
                PayNow: '現在支付'
            },
            SelectedRlus: {
                Cek: '我已知悉以下情況將觸發系統風控並導致賬戶受限：',
                Rlus1: '使用虛擬卡支付Telegram月費會員。',
                Rlus2: '綁定Apple Pay。',
                Rlus3: '在加密貨幣相關商戶消費，如 Binance, OKEx, Coinbase 等。',
                Rlus4: '過高的支付失敗率或消費退款率。',
                NoRlusTip: '請勾選下方提示。',
                Rlus2Special: '绑定Apple Pay或Paypal。'
            }
        }

    },
    // 卡明細頁
    CardDetail: {
        Card: '卡號', // 卡號
        CPlaceHolder: '模糊查找', // 模糊查找
        Tag: '標簽', // 標簽
        Cardholder: '持卡人', // 持卡人
        Amount: '金額', // 金額
        BeforeDate: '此時間前',
        AfterDate: '此時間后',
        SelectStatus: '狀態',
        SelectType: '類型',
        TypeLabel1: '授權',
        TypeLabel2: '撤銷',
        TypeLabel3: '退款',
        Search: '查詢', // 查詢
        Clear: '清除', // 清除
        Guide: '明細指引',
        Deposit: '賬戶充值',
        Withdraw: '賬戶提現',
        Topup: '虛擬卡增值',
        TopupFee: '虛擬卡增值手續費',
        IssueFee: '開卡費',
        TransactionFee: '虛擬卡交易手續費',
        DeclineFee: '授權失敗手續費',
        Guarantee: '保證金',
        // 表格字段
        Table: {
            ConsumptionCard: '虛擬卡', // 消費卡
            ConsumptionTime: '時間', // 消費時間
            ConsumerMerchant: '商戶', // 消費商戶
            AuthorizedAmount: '授權', // 授權金額
            AuthorizedCurrency: '授權幣種',  // 授權幣種
            SettlementAmount: '結算', // 結算金額
            SettlementCurrency: '結算幣種', // 結算幣種
            Type: '類型', // 類型
            Status: '狀態' // 狀態
        }
    },
    // 賬戶明細頁
    AccountDetail: {
        Time: '時間', // 時間
        Type: '類型', // 類型
        Amount: '金額',  // 金額
        Status: '狀態', // 狀態
        Card: '虛擬卡', // 卡
        Remark: '備注', // 備注
        Description: '補充信息', // 説明
        FrozenWithdrawTip: '提現金額會被標記為凍結，處理完成後更新為Complete',
        Statistics: {
            CardNumber: '卡號',
            Merchant: '授權商戶',
            Amount: '授權金額',
            Status: '狀態',
            TransactionTime: '授權時間',
            Close: '關閉'
        }
    },
    // 流水交易报告
    ReportBalanceTransaction: {
        Report: '報告',
        Item1: {
            Title: '餘額流水報告',
            Desc: '已結算的賬戶交易記錄，用於追蹤您賬戶餘額變動',
            ReportBtn: '導出報告'
        },
        Item2: {
            Title: '虛擬卡交易記錄報告',
            Desc: '虛擬卡已交易的詳情記錄',
            ReportBtn: '導出報告'
        },
        ReportCardTransaction: '導出虛擬卡交易記錄報告',
        ReportBalance: '導出餘額流水報告',
        FileIdear: '文件格式',
        NoSelectDateTip: '請選擇報告時間段',
        Export: '導出',
    },
    // 微信綁定頁
    WechatBind: {
        WechatLogin: '微信登入',
        Tip: '您的微信賬號未綁定，請輸入賬戶信息完成綁定，此操作只需進行一次，綁定成功后即可使用微信掃碼登入。（若您未有FotonCard賬戶，請先',
        Register: '注冊',
        Email: '電郵地址',
        Password: '密碼',
        Submit: '提交',
        MaTip: '請使用微信掃描二維碼登入',
        Code401: '微信賬號未綁定，請先進行綁定',
        Code200: '登入成功',
    },
    // 登录页
    LoginFields: {
        LoginAccount: '輸入密碼', // 登入账户
        PlaceholderEmail: '電郵地址', // 邮箱地址
        InputCode: '輸入驗證碼',
        PlacehoderPassword: '登入密碼', // 登入密码
        Login: '登入', // 登录
        NextStep: '下一步', // 下一步
        RegisterFields: '注冊', // 注册
        FotonCardInfo: 'FotonCard爲您提供安全、便捷、灵活的虛擬卡服務，覆蓋多種消費場景，開卡即用，實時到賬，極速消費。',
        ForgetPassword: '忘記密碼',
        PasswordOrangeTip: '密碼經過加密處理，FotonCard不會知悉您的密碼，請勿向任何人透露密碼', 
        // 重置密碼
        ResetPassword: '重置您的登入密碼',
        NewPassword: '新密碼',
        PasswordTip: '密碼至少6位並須包含英文字母和數字',
        ConfirmPassword: '確認密碼',
        ComfirmAndSignIn: '確認並登入',
        // 2fa
        Tips: '系統檢測到您的登入環境發生變化，本次登入需進行2FA認證，電郵驗證碼已發送至您的郵箱',
        Welcome: '歡迎與我們聯係：info@fotoncard.com',
        WechatScan: '微信掃碼',
        // 備用碼
        UseRecoveryCode: '使用備用碼',
        RecoveryCode: '備用碼',
        UseEmailCode: '使用郵箱驗證碼',
        InputRecoveryCode: '輸入備用碼',
    },
    // 注册页
    RegisterFields: {
        CreateAccount: '創建賬戶', // 创建账户
        CreateTip: '填寫以下信息創建您的賬戶',
        PlaceholderEmail: '電郵地址', // 邮箱地址
        PlaceholderEmailCode: '郵箱驗證碼', // 邮箱验证码
        PlacehoderPassword: '登入密碼', // 登入密码
        PlacehoderConfirmPassword: '確認密碼', // 确认密码
        None: '請輸完整填寫表單', // 不能为空
        SendCode: '重新發送',
        SendCode2: '發送驗證碼',
        InvitationCode: '邀請碼',
        CETip: '請輸入正確的郵箱',
        CRPTip: '兩次密碼輸入不正確',
        PasswordTip: '密碼至少6位並須包含英文字母和數字',
        YahooTip: '當前暫不支持yahoo.com電郵注冊，請使用其他電郵',
        // Policy 
        Accept: '同意',
        PrivacyPolicyand: '《隱私政策》',
        And: '、',
        CookiePolicy: '《Cookie政策》'
    },
    Footer: {
        // 如有疑問請聯係在綫客服或發送電郵至support@fotoncard.com
        EmailLink: '如有疑問請聯係在綫客服或發送電郵至'
    }
}