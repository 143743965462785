/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2023-07-20 10:59:34
 * @LastEditors: 
 * @LastEditTime: 2023-07-20 11:02:42
 */
export function getCookie(name) {
    var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
    if(arr=document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
}